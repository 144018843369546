.guarantee-detail-card {
    padding: 1rem 0;
}

.customer-template-card {
    background: $white-beige;
    padding: 1rem;
    margin-bottom: .5rem;
    display: flex;

    &:nth-child(even) {
        background: $white;
    }
}

.product-catalog-card {
    border-top: 1px solid $grey;
    &:first-child{
        border-top: 0 none;
    }
    &:last-child {
        border-bottom: 1px solid $grey;
    }

    .card-inner-link {
        display: block;
        padding: 1rem 0;

        &:hover {
            color: $primary-link-color;
        }
    }
}

.guarantee-bookings-card {
    position: relative;
    padding: 2rem $container-padding-x 1rem $container-padding-x;

    .collapse-box {
        background-color: $bg-color;
        padding: 1rem;
        font-size: .875rem;
        position: relative;

        .close-icon {
            position: absolute;
            top: .5rem;
            right: .5rem;
        }
    }

    .card-column {
        padding: 0 !important;
        display: flex;
        flex-direction: column;

        .card-head {
            font-size: 12px;
            font-weight: 600;
        }

        .label {
            min-width: 3.5rem;
            min-height: 3.5rem;
            border-radius: 50%;
            background-color: $brand-light-color;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 80%;
            line-height: 1.125;

            &.label-accent-color {
                background-color: $brand-accent-color;
            }
        }

        .next {
            font-size: 2rem;
            color: $grey;
            transition: color .3s;
        }

        a {
            color: #484D48;

            &:hover {
                text-decoration: none;
                color: $global-link-hover-color;

                .svg-icon,
                .svg-icon #Ellipse_130,
                .svg-icon #Pfad_1072,
                .svg-icon #Pfad_438{
                    fill: $global-link-hover-color;
                }
            }

            .svg-icon,
            .svg-icon #Ellipse_130,
            .svg-icon #Pfad_1072,
            .svg-icon #Pfad_438 {
                fill: #484D48;
            }
        }

        .icon-wrap {
            width: 70px;
            text-align: center;
            margin-left: 1.5rem;
            font-size: 12px;
            font-weight: 400;
            .icon {
                width: 36px;
            }
        }
        @media (max-width: 991px) {
            .icon-wrap {
                margin-left: 0;
            }
        }
    }

    .box-icon {
        width: 28px;
        margin-bottom: .5rem;
        max-width: 90%;
    }

    > a:first-child {
        color: inherit;
        display: block;
        padding: 1rem;
        margin: 0 -1rem;
        transition: background-color .3s ease-in-out;

        &:hover {
            text-decoration: none;
            background-color: $bg-color;

            .next {
                color: $black;
            }
        }
    }

    .small {
        font-size: 14px;

        &.link {
            font-weight: 600;
        }
    }
}

.guarantee-card-component-wrap {
    background-color: $primary;
    padding: 2rem 3rem;
    font-size: 12px;
    position: relative;

    .card-head {
        font-weight: 600;
    }

    .close-icon {
        position: absolute;
        top: 1rem;
        right: 1.5rem;
        cursor: pointer;
    }
}

.bt-1 {
    border-top: 1px solid;
    padding-top: 15px;
    font-weight: bold;
}
