.notify-modal-open {
    .notify-modal {
        .modal-header {
            background-color: $white;

        }

        .modal-body {
            background-color: $white;

            .label {
                position: absolute;
                top: 0;
                right: 0;
                width: 5rem;
                padding: 1rem;
            }
        }
    }
}

.modal-content {
    border-radius: 0 !important;
    border: none !important;

    .title{
        font-size: 24px;
        font-weight: 600;
    }
}

.modal-bg-dark {
    background: rgb(0, 0, 0, .5) !important;

    .fade {
        background: rgb(0, 0, 0, .5) !important;
    }
}

.gc-overlay {
    //position: absolute;
    //top: 0;
    //height: 100%;
    //width: 100%;
    background: #ffffff;
    //z-index: 1020;

    //&.product-wrap {
    //    z-index: 1022 !important;
    //}
    //
    //&.manufacturer-wrap {
    //    z-index: 1021 !important;
    //}
}

@include media-breakpoint-down(sm) {
    .responsive-modal {
        .modal-dialog {
            margin: 0;
            min-width: 100%;

            .modal-content {
                border: none;
                height: 100%;
            }
        }
    }

    .responsive-modal-catalog {
        .modal-dialog {
            margin: 0;
            min-width: 100%;

            .modal-content {
                top: 40px;
                border: none;
                height: calc(100% - 40px);
            }
        }

        ~ .modal-backdrop {
            top: 40px;
        }
    }
}

@include media-breakpoint-up(sm) {
    .notify-modal-open {
        overflow: inherit !important;
        padding-right: 0 !important;

        .notify-modal {
            position: absolute;
            width: auto;
            height: auto;
            top: 2rem;
            right: 5rem;
            left: auto;

            .modal-dialog {
                position: unset;
                width: 500px;
                z-index: 9999;
                //top: 500px;
                right: 0;
                margin: 2rem;
            }
        }

        .modal-backdrop {
            display: none;
        }
    }
}

.modal-logo{
    max-width: 100%;
    height: auto;
    width: 100px;
    @media (min-width: 992px) {
        width: 175px;
    }
}

@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 1000px;
    }
}
