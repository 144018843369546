.notification-wrapper {
    border-radius: 0 !important;
    background: #ffffff;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    min-height: 300px;
    padding: 2rem;

    @media (min-width: 680px) {
        min-width: 580px;
    }

    .column {
        padding: 2rem;
    }

    .close-icon {
        text-align: right;
        margin-bottom: 1rem;
    }

    .title {
        font-weight: 600;
        margin-bottom: 1rem;
    }

    .description {
        margin-bottom: 2rem;
    }
}
