.lh-0 {
    line-height: 1;
}
.lh-1 {
    line-height: 1.25rem;
}
.lh-2 {
    line-height: 1.5rem;
}
.lh-3 {
    line-height: 1.75rem;
}
.lh-4 {
    line-height: 2rem;
}

.text-primary {
    color: $primary-link-color!important;
}
