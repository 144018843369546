.btn-circle.btn-lg, .btn-group-lg > .btn-circle.btn {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1rem;
}

.sidebar .nav-item .nav-link {
    padding: .5rem 1rem !important;
}

form.user .form-control-user,
form.user .btn-user {
    border-radius: 0 !important;
}
form.user .form-control-user {
    padding: 0.375rem 0.75rem;
}
