.indicators {
    padding-left: 0;
    list-style: none;
    display: flex;
    justify-content: center;

    li {
        a {
            padding: 1rem;

            &:hover,
            &.active {
                color: $grey;
            }
        }
    }
}
