.bg-gray {
    background-color: $bg-color!important;
}
.bg-green {
    background-color: $pale-green!important;
}

.bg-welldone {
    background-color: $well-done-color !important;
}
.bg-attention {
    background-color: $attention-color !important;
}
.bg-warning {
    background-color: $warning-color !important;
}
.bg-danger {
    background-color: $danger-color !important;
}

@include media-breakpoint-up(lg) {
    .bg-lg-white {
        background-color: white!important;
    }
}
