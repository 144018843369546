footer {
    color: $white;
    padding: 15px 25px;
    font-size: 16px;
    display: flex;
    align-items: center;

    a {
        color: $white;

        &:hover {
            color: $white;
        }
    }

    .footer-container {
        height: 100%;
        display: flex;
        align-items: center;
    }
}
