.accordion {
    .accordion-item {
        background-color: $bg-color;
        padding: 2rem;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        .header {
            display: flex;

            .collapse-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 1.5rem;
                height: 1.5rem;
                position: relative;
                color: $black;
                margin-left: auto;

                &::after {
                    font-family: "bootstrap-icons";
                    content: '\F64D';
                    position: absolute;
                    font-size: 1.5rem;
                    transition: transform .3s ease-in-out;
                    -ms-transition: -ms-transform .3s ease-in-out;
                    -webkit-transition: -webkit-transform .3s ease-in-out;
                }

                &[aria-expanded="true"]::after {
                    transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                }
            }
        }

    }
}
