@import "buttons";
@import "form";
@import "borders";
@import "cards";
@import "alerts";
@import "modal";
@import "text";
@import "indicators";
@import "background";
@import "accordion";
@import "logos";
@import "notification";
@import "icons";

.cursor-pointer {
    cursor: pointer;
}

.fixed-full-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    &.has-index {
        z-index: -1;
    }
}

.hamburger-hsw{
    display: inline-block;
    height: 70px;
    vertical-align: middle;
}

@media (min-width: 992px) {
    .custom-calc-padding {
        padding-left: calc(5rem + 15px);
    }
}

@media (min-width: 1600px) {
    .d-xxl-none{
        display: none !important;
    }
    .d-xxl-block{
        display: block !important;
    }
}
@media (max-width: 991px) {
    h1,h2,h3 {
        font-size: $font-size-base !important;
    }
    .heading-container{
        padding: 1rem 2rem !important;
    }
    .heading-container .title{
        font-size: $font-size-base !important;
    }
    .guarantee-bookings-card{
        padding: 1rem 2rem 1rem 2rem !important;
    }
    .guarantee-bookings-card .card-column .icon-wrap{
        font-size: 9px !important;
    }
    .hamburger-hsw{
        display: inline-block;
        height: 40px;
        vertical-align: middle;
    }
}

.btn-close {
    padding-right: 25px;
    cursor: pointer;
}

@media (min-width: 815px) {
    .h-md-100 {
        height: 100% !important;
    }
}

