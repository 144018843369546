i {
    &[class^="icon-"] {
        --icon-size: 30px;
        width: var(--icon-size);
        height: var(--icon-size);
        display: inline-block;
    }
    &.icon-link-invoice {
        -webkit-mask: url("../../../images/icons/link_invoice.svg") no-repeat center;
        mask: url("../../../images/icons/link_invoice.svg") no-repeat center;
    }
    &.icon-reset {
        -webkit-mask: url("../../../images/icons/reset_outline.svg") no-repeat center;
        mask: url("../../../images/icons/reset_outline.svg") no-repeat center;
    }
    &.icon-trash {
        -webkit-mask: url("../../../images/icons/trash_outline.svg") no-repeat center;
        mask: url("../../../images/icons/trash_outline.svg") no-repeat center;
    }
    &.icon-file {
        -webkit-mask: url("../../../images/icons/file_outline.svg") no-repeat center;
        mask: url("../../../images/icons/file_outline.svg") no-repeat center;
    }
    &.icon-plus {
        -webkit-mask: url("../../../images/icons/plus_square_with_holes_outline.svg") no-repeat center;
        mask: url("../../../images/icons/plus_square_with_holes_outline.svg") no-repeat center;
    }
    &.icon-check {
        -webkit-mask: url("../../../images/icons/check_outline.svg") no-repeat center;
        mask: url("../../../images/icons/check_outline.svg") no-repeat center;
    }
    &.icon-shield-document {
        -webkit-mask: url("../../../images/icons/shield-document.svg") no-repeat center;
        mask: url("../../../images/icons/shield-document.svg") no-repeat center;
    }
    &.icon-user-arrow {
        -webkit-mask: url("../../../images/icons/user-arrow-up.svg") no-repeat center;
        mask: url("../../../images/icons/user-arrow-up.svg") no-repeat center;
    }
    &.icon-exclamation-triangle {
        -webkit-mask: url("../../../images/icons/exclamation-triangle_outline.svg") no-repeat center;
        mask: url("../../../images/icons/exclamation-triangle_outline.svg") no-repeat center;
    }
}
