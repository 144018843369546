header {
    @media(min-width: 769px) {
        margin-bottom: 1rem;
    }
    height: 40px;

    nav {
        height: 100%;

        .left-container {
            height: 100%;

            .nav-btn {
                color: $white;
                font-size: 2rem;
                cursor: pointer;
                padding: 0 .5rem;

                height: 40px;
                display: flex;
                align-items: center;
            }
        }

        .logo-wrap {
            img {
                height: 30px;
            }
        }

        .right-container {
            display: flex;
            align-items: center;
            height: 100%;

            .name-wrap {
                color: $white;
            }

            .link-wrap {
                color: $white;
                height: 100%;

                .nav-btn {
                    cursor: pointer;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding: 0 .5rem;

                    .bi-person {
                        font-size: 1.25rem;
                    }
                }
            }
            .nav-icon-svg-white {
                width: 20px;
                height: 20px;
                filter: invert(1) sepia(1) saturate(0) hue-rotate(0deg);
            }
        }
    }

    /* Side menu navigation */
    .side-nav {
        $item-padding-size: 2rem;

        width: 100%;
        position: fixed;
        top: 0;
        left: -100%;
        height: 100vh;
        transition: 0.5s;
        z-index: 99999;
        background-color: $nav-bg;

        .nav-link {
            margin-bottom: .5rem;
        }

        .nav-link, .bi, a {
            color: $nav-link-color !important;

            .nav-icon-svg {
                stroke: $nav-link-color !important;

                &.has-fill {
                    fill: $nav-link-color !important;
                }
            }
            .nav-icon-svg-white {
                filter: invert(1) sepia(1) saturate(0) hue-rotate(0deg);
            }

            :hover {
                text-decoration: none;
                color: $nav-link-hover-color !important;
            }
        }

        .nav-link:hover {
            .nav-icon-svg {
                stroke: $nav-link-hover-color !important;

                &.has-fill {
                    fill: $nav-link-hover-color !important;
                }
            }
            .nav-icon-svg-white {
                filter: invert(1) sepia(0) saturate(1) hue-rotate(52deg) opacity(0.8);
            }
        }

        &.active {
            left: 0;
            overflow-x: hidden;
            overflow-y: scroll;
        }

        &.right {
            left: auto;
            right: -100%;

            &.active {
                right: 0;
            }
        }

        .icon-wrap {
            width: 70px;
            padding: 2rem;
            padding-bottom: 0;
            font-size: 20px;
            cursor: pointer;
        }

        .navbar-nav {
            padding: $item-padding-size;

            .nav-item {

                &.main-item {
                    font-weight: 600;
                }

                .nav-link {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 600;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .divider {
                background-color: $dark;
            }
        }
    }
}
@media (min-width: 992px) {
    header {
        height: 70px;
    }
}
@include media-breakpoint-up(md) {
    header {

        nav {
            .left-container {
                .nav-btn {
                    padding: .5rem 1rem;
                    height: 100%;
                }
            }

            .right-container {
                .link-wrap {
                    .nav-btn {
                        padding: .5rem 1rem;
                    }
                }
            }
        }

        .side-nav {
            width: 400px;
            left: -400px;

            &::-webkit-scrollbar {
                width: 0.5rem;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }
}
