@import url("https://p.typekit.net/p.css?s=1&k=hay7hzk&ht=tk&f=39773.39780.39783&a=5309578&app=typekit&e=css");

@font-face {
    font-family: "obvia";
    src: url("https://use.typekit.net/af/280725/00000000000000007735bd10/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/280725/00000000000000007735bd10/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/280725/00000000000000007735bd10/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 300;
    font-stretch: normal;
}

@font-face {
    font-family: "obvia";
    src: url("https://use.typekit.net/af/7318a4/00000000000000007735bd1f/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/7318a4/00000000000000007735bd1f/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/7318a4/00000000000000007735bd1f/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
}

@font-face {
    font-family: "obvia";
    src: url("https://use.typekit.net/af/a9b63c/00000000000000007735bd20/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3") format("woff2"), url("https://use.typekit.net/af/a9b63c/00000000000000007735bd20/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3") format("woff"), url("https://use.typekit.net/af/a9b63c/00000000000000007735bd20/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 800;
    font-stretch: normal;
}
