// Boostrap 4
@import "~bootstrap/dist/css/bootstrap.css";

// Use of the bootstrap mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

//Pikaday
@import '~pikaday/css/pikaday.css';

//Fonts
@import "fonts/all";
@font-face {
    font-family: "hws-font";
    src: url('fonts/hsw/font.woff') format('woff2'), url('fonts/hsw/font.woff') format('woff');
}

@import "variables";
@import "components/all";
@import "templates/all";
