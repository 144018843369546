//:root {
//    --brand: #{$brand-color};
//    --brand-accent: #{$brand-accent-color};
//    --dark: #343A3F;
//}
//
//.btn {
//    border-radius: 0;
//
//    &.btn-hsw {
//        background-color: #2f4c35;
//        border-color: #2f4c35;
//        color: #ffffff;
//
//        &:hover,
//        &:focus {
//            color: #ffffff;
//            background-color: #2F4C35A5;
//            border-color: #2F4C35A5;
//        }
//    }
//
//    &.btn-lg {
//        min-height: 50px;
//        font-size: 1.0625rem;
//        font-weight: bold;
//        display: flex;
//        align-items: center;
//        justify-content: center;
//    }
//
//    &.btn-submit {
//        padding: 0.5rem 1rem;
//        background-color: var(--brand-accent);
//        border: 2px solid var(--brand-accent);
//        color: $white;
//
//        &:hover,
//        &:focus {
//            background-color: $white;
//            color: var(--brand-accent);
//        }
//    }
//
//    &.btn-back {
//        padding: 0.5rem 3rem;
//        border: 2px solid var(--dark);
//
//        &:hover {
//            color: $white;
//            background-color: var(--dark);
//        }
//    }
//
//    &.btn-close {
//        background-color: var(--brand);
//        color: var(--dark);
//        border: 2px solid var(--brand);
//
//        &:hover,
//        &:focus {
//            background-color: $white;
//        }
//    }
//
//    &.btn-text-secondary {
//        color: $light-green;
//        padding: 0.5rem 0;
//        font-weight: bold;
//
//        &:hover,
//        &:focus {
//            text-decoration: underline;
//        }
//    }
//
//    &.btn-text-black {
//        color: $black;
//        padding: 0;
//
//        &:hover,
//        &:focus {
//            color: $dark-grey;
//        }
//    }
//
//    &.btn-fill-light {
//        background-color: $bg-color;
//        color: $black;
//
//        &:hover,
//        &:focus {
//            background-color: $grey;
//        }
//    }
//
//    &.btn-outline-secondary {
//        color: $light-green;
//        border-color: $light-green;
//
//        &:hover,
//        &:focus {
//            color: $white;
//            background-color: $light-green;
//        }
//    }
//
//    &.btn-tab {
//        padding: 0.5rem 1rem;
//        font-weight: bold;
//
//        &:hover,
//        &:focus {
//            color: $grey;
//        }
//
//        &.active {
//            background: $brand-light-color;
//            color: $white;
//            position: relative;
//
//            &::after {
//                content: '';
//                position: absolute;
//                width: 0;
//                height: 0;
//                border-left: 1rem solid transparent;
//                border-right: 1rem solid transparent;
//                border-top: 1rem solid $light-green;
//                right: calc(50% - 1rem);
//                bottom: -1rem;
//            }
//        }
//    }
//
//}
//
//.link-text-accent {
//    color: var(--brand-accent);
//
//    &:hover {
//        color: var(--brand-accent);
//    }
//}
//
//.link-text-black {
//    color: $black;
//    text-decoration: underline;
//
//    &:hover {
//        color: $dark-grey;
//        text-decoration: none;
//    }
//}
//
//.link-text-white {
//    color: $white;
//    text-decoration: underline;
//
//    &:hover {
//        color: $dark-grey;
//        text-decoration: none;
//    }
//}
//
//.link-text-dark {
//    color: $dark;
//    text-decoration: underline;
//
//    &:hover {
//        color: $dark-grey;
//        text-decoration: none;
//    }
//}

$btn-border: 1px solid transparent;
$btn-border-radius: 20px;

.btn-box{
    text-align: center;
    .btn-box-content{
        display: inline-block;
        position: relative;
        img{
            position: absolute;
            left: -10px;
            top: 50%;
            margin-top: -9px;
            height: 18px;
            width: auto;
            transform: translateX(-100%);
        }
    }
}

.btn {
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    height: 40px;
    padding: 9px 38px;
    width: 300px;
    max-width: 95%;
    @media(max-width: 768px) {
        margin-bottom: 20px;
    }
    border-radius: $btn-border-radius;
    text-decoration: none;
    margin-left: 10px;
    margin-right: 10px;

    &.btn-small{
        width: 230px;
    }

    position: relative;
    img{
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -9px;
        height: 18px;
        width: auto;
    }

    &.btn-light {
        color: $btn-text-color-dark;
        background: $btn-light-bg;
        border: $btn-border;

        &:hover {
            .btn-box-content,
            span{
                color: $btn-text-color-dark;
            }
            background: $btn-light-hover-bg;
            border: $btn-border;
        }
    }

    &.btn-success {
        color: $btn-text-color-dark;
        background: $btn-success-bg;
        border: $btn-success-bg;

        &:hover {
            .btn-box-content,
            span{
                color: $btn-text-color-dark;
            }
            background: $btn-success-hover-bg;
            border: $btn-success-hover-bg;
        }
    }

    &.btn-primary {
        color: $btn-text-color-dark;
        background: $btn-primary-bg;
        border: $btn-border;

        &:hover {
            .btn-box-content,
            span{
                color: $btn-text-color-dark;
            }
            background: $btn-primary-hover-bg;
            border: $btn-border;
        }
    }

    &.cc-primary {
        color: #252928;
        background: $cc-button-primary;
        border: $cc-button-primary-border;

        &:hover {
            .btn-box-content,
            span{
                color: #252928;
            }
            background: $cc-button-primary-hover;
            border: $cc-button-primary-hover-border;
        }
    }

    &.cc-secondary {
        color: #252928;
        background: $cc-button-secondary;
        border: $cc-button-secondary-border;

        &:hover {
            .btn-box-content,
            span{
                color: #252928;
            }
            background: $cc-button-secondary-hover;
            border: $cc-button-secondary-hover-border;
        }
    }

    &.hsw-primary {
        color: #252928;
        background: $hsw-button-primary;
        border: $hsw-button-primary-border;

        &:hover {
            .btn-box-content,
            span{
                color: #252928;
            }
            background: $hsw-button-primary-hover;
            border: $hsw-button-primary-hover-border;
        }
    }

    &.hsw-secondary {
        color: #252928;
        background: $hsw-button-secondary;
        border: $hsw-button-secondary-border;

        &:hover {
            .btn-box-content,
            span{
                color: #252928;
            }
            background: $hsw-button-secondary-hover;
            border: $hsw-button-secondary-hover-border;
        }
    }

    &.btn-dark {
        color: $btn-text-color-light;
        background: $btn-dark-bg;
        border: $btn-border;

        &:hover {
            .btn-box-content,
            span{
                color: $btn-text-color-light;
            }
            background: $btn-dark-hover-bg;
            border: $btn-border;
            color: $btn-text-color-light;
        }

        .btn-text {
            color: $btn-text-color-light !important;
            &:hover {
                color: $btn-text-color-light !important;
            }
        }
    }

    &.disabled {
        pointer-events: none;
        img,
        span {
            opacity: .45;
        }
    }
}
