$form-border: 1px solid #D5D5D5;
$form-bg-color: #F6F6F4;
$form-control-height: 40px;
$form-placeholder-font-color: #C1C1C1;

form {
    textarea {
        border: $form-border;
    }

    .form-group {
        label,
        .form-check-label {
            font-size: 16px;
            font-weight: 600;
        }

        .check-label-box {
            font-weight: 600;
        }

        .form-label {
            font-size: 12px;
            color: $dark;
            margin-bottom: .25rem;
            font-weight: 600;
            &.big-label{
                font-size: 20px;
            }
        }

        .form-control {
            min-height: $form-control-height;
            border: $form-border;
            background-color: $form-bg-color;
            border-radius: 0;

            &:focus {
                border-color: #858585;
                box-shadow: none;
            }

            &::placeholder {
                color: $form-placeholder-font-color;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $form-placeholder-font-color;
            }

            &::-ms-input-placeholder {
                color: $form-placeholder-font-color;
            }
        }
    }
}

.form-check-box {
    position: relative;
    cursor: pointer;
    width: 100%;

    input {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
    }

    .check-label {
        display: flex;
        margin-bottom: 0;

        .check-label-box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            width: 20px;
            border: 2px solid $black;
            background-color: $white;
            margin-right: 0.5rem;
            cursor: pointer;
        }
    }

    input:checked + .check-label .check-label-box {
        border: 1px solid $cc-form-check-primary;
        background-color: $cc-form-check-primary;

        i {
            margin-top: -2px;
            background: $black;
        }
    }
}


.form-check-hsw {
    position: relative;
    cursor: pointer;
    width: 100%;
    padding-left: 0;

    .form-check-input {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
    }

    .form-check-label {
        display: flex;
        margin-bottom: 0;
        font-weight: 600;
        &:hover {
            color: initial;
        }

        .check-label-box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            width: 20px;
            border: 2px solid $black;
            background-color: $white;
            margin-right: 0.5rem;
            cursor: pointer;
        }
    }

    .form-check-input:checked + .form-check-label .check-label-box {
        border: 1px solid $cc-form-check-primary;
        background-color: $cc-form-check-primary;

        i {
            margin-top: -2px;
            background: $black;
        }
    }
}
.form-check-hsw-sub {

    position: relative;
    cursor: pointer;
    padding-left: 0;

    .form-check-input {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
    }

    .form-check-label {
        display: flex;
        margin-bottom: 0;
        &:hover {
            color: initial;
        }

        .check-label-box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            width: 20px;
            border: $form-border;
            background-color: $white-beige;
            color: $white-beige;
            font-size: 1.125rem;
            margin-right: 0.5rem;
            cursor: pointer;
        }
    }

    .form-check-input:checked + .form-check-label .check-label-box {
        border: 1px solid $cc-form-check-primary;
        background-color: $cc-form-check-primary;

        i {
            margin-top: -2px;
            background: $black;
        }
    }
}

.filter-brand-group {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.25rem;

    .filter-brand {
        position: relative;
        width: 33.33%;
        height: 50px;
        box-sizing: border-box;
        padding: 0.25rem;

        .logo {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-color .25s ease;
            background-color: $grey;
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }

        input[type=checkbox]:checked ~ .logo {
            background-color: $brand-light-color;
        }

    }
}
.form-select-hswm {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    border: $form-border;
    background-color: $form-bg-color;
    width: 100%;
    position: relative;
    height: 90px;
    select {
        width: 100%;
        padding: 10px;
        position: absolute;
        top: -10px;
        left: -10px;
        bottom: -10px;
        width: calc(100% + 10px);
        background: transparent;
    }
}
.form-select-hsw {
    position: relative;
    height: $form-control-height;
    background-color: $form-bg-color;

    .form-control {
        position: absolute;
        background-color: transparent!important;
        z-index: 1;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        padding: 0 45px 0 10px;
    }

    &:before {
        content: "";
        position: absolute;
        border-left: $form-border;
        width: 40px;
        height: 100%;
        right: 0;
        top: 0;
        z-index: 0;
    }

    &:after {
        content: "";
        position: absolute;
        right: calc( (40px - 13px)/2 );
        top: calc( (40px - 9px)/2 );
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 9px 6.5px 0 6.5px;
        border-color: #343a3f transparent transparent transparent;
        z-index: 0;
    }

    .is-invalid {
        background-position: right 3rem center!important;
    }

    .invalid-feedback {
        margin-top: 0;
        padding-top: 44px;
    }

    &.form-date {
        &:after {
            content: '\F1F6';
            font-family: bootstrap-icons;
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.form-upload {
    position: relative;

    label,
    .form-label {
        background-color: $bg-color;
        border: $form-border;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem;
        margin-bottom: 0;
        font-weight: bold;
        cursor: pointer;

        i {
            font-size: 1.5rem;
        }
    }

    &.form-upload-button {
        .form-label {
            background-color: #C9E6EF;
            padding: 1rem;
            border: none;
        }
    }

    input[type="file"] {
        font-size: 80%;
    }
    input[type="file"]::-ms-browse {
        display: none;
    }
    input[type="file"]::-webkit-file-upload-button {
        display: none;
    }
}

.dropdown-menu.show {
    transform: unset !important;
}

.custom-dropdown {
    .custom-dropdown-btn {
        background-color: $bg-color;
        position: relative;
        height: 40px;

        .dropdown-toggle {
            background-color: transparent;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;

            &.is-invalid {
                background-position: right 3rem center!important;
            }
        }

        .btn-appearance {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            border-left: $form-border;

            &:after {
                content: "";
                position: absolute;
                right: calc( (40px - 13px)/2 );
                top: calc( (40px - 9px)/2 );
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 9px 6.5px 0 6.5px;
                border-color: #343a3f transparent transparent transparent;
            }
        }

        .dropdown-toggle:disabled,
        .dropdown-toggle.disabled-drop-down {
            color: $grey;
            cursor: default;

            + .btn-appearance {
                &:after {
                    border-color: $grey transparent transparent transparent;
                }
            }
        }
    }

    &.show > .dropdown-toggle {
        background-color: $white;
    }

    > .dropdown-toggle {
        &:focus {
            box-shadow: none;
        }
    }

    > .dropdown-menu {
        width: 100%;
        border: $form-border;

        margin-top: -3px;
        top: 0;
        padding: 0;

        .inner-button {
            i {
                font-size: 1.5rem;
                line-height: 1;
            }
        }

        .inner {
            padding: 1rem;
        }

        .dropdown-toggle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            padding: 0 1rem;
            position: relative;
        }

        .dropdown-item {
            padding: 0;
            display: flex;
            margin: .5rem 0;

            .icon {
                min-width: 3rem;
                height: 3rem;
                background-color: $form-bg-color;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: .5rem solid $white;
                box-sizing: content-box;

                i {
                    font-size: 1.5rem;
                }
            }
            .name {
                border-bottom: $form-border;
                width: 100%;
                padding: 0 1rem;
                display: flex;
                align-items: center;
            }
        }
    }

    .dropdown-menu.show {
        transform: unset !important;
    }
}

@include media-breakpoint-down(sm) {
    body.custom-dropdown-open {
        height: 100vh;
        overflow-y: hidden;

        header {
            position: sticky;
            top: 0;
            z-index: 999;
        }
    }

    .custom-dropdown {
        position: unset!important;

        > .dropdown-menu  {
            &.show {
                position: fixed!important;
                padding: 0;
                width: 100%;
                right: 0;
                left: 0;
                bottom: 0;
                border: 0;

                .inner-header {
                    height: 43px;
                    color: $white;
                    text-align: center;
                    font-size: 1.75rem;
                    align-content: center;
                    justify-content: center;
                    line-height: 1.7;
                }

                .inner-button {
                    i {
                        font-size: 2.25rem;
                    }
                }

                .inner {
                    height: calc( 100vh - 43px);
                    overflow: scroll;
                    padding: 1rem;
                }
            }
        }
    }
}
@include media-breakpoint-up(sm) {
    .custom-dropdown {
        /* Scroll bar */
        .dropdown-menu {
            .inner {
                height: 300px;
                overflow-y: scroll;
                padding-right: 0;

                &::-webkit-scrollbar {
                    width: 1rem;
                }
                &::-webkit-scrollbar-track {
                    background: $bg-color;
                }
                &::-webkit-scrollbar-thumb {
                    background: #D5D5D5;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #BFBFBF;
                }
            }
        }
    }
}

.hsw-link {
    color: $hsw-link-color;
    text-decoration: underline;
    text-underline: $hsw-link-color !important;
    &:hover{
        color: $hsw-link-hover-color;
        text-underline: $hsw-link-hover-color;
    }
}
.cc-link {
    color: $cc-link-color;

    [class^="icon-"] {
        background-color: $cc-link-color;
    }

    &:hover{
        color: $cc-link-hover-color;
        text-underline: $cc-link-hover-color;
        cursor: pointer;

        [class^="icon-"] {
            background-color: $cc-link-hover-color;
        }
    }
}
.btn-link {
    &.btn-edit {
        font-weight: 600;
        color: $cc-link-color;
        cursor: pointer;
        text-decoration: none;
        img {
            filter: invert(0.6) sepia(1) saturate(100) hue-rotate(190deg) opacity(0.6);
        }
        &:hover {
            color: $cc-link-hover-color;
            img {
                filter: invert(0.6) sepia(1) saturate(100) hue-rotate(190deg) opacity(0.4);
            }
        }
    }
}
.hidden {
    display: none !important;
}
.checkers-wrapper {
    background: $form-bg-color;
    padding: 8px 15px;
    height: 40px;
    .off-stage { position: absolute; left: -5000px;}
    .checker {
        cursor: pointer;
        margin-bottom: 5px;
        align-items: center;
        width: auto;
        float: left;
        margin-right: 15px;
    }
    input:checked + .check-label-box {
        border: 1px solid $cc-form-check-primary;
        background-color: $cc-form-check-primary;

        i {
            margin-top: -2px;
            background: $black;
        }
    }
}
input:checked + .form-check-label .check-label-box {
    border: 1px solid $cc-form-check-primary;
    background-color: $cc-form-check-primary;

    i {
        margin-top: -2px;
        background: $black;
    }
}
