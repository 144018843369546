.title-container {
    background: $white;
    padding: 2rem 1rem 4rem 1rem;
    margin-bottom: .5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    &.direction-row {
        flex-direction: row;
        .flex-columns {
            flex-direction: row;
            display: flex;
            width: 100%;
        }
        strong { font-weight: 600; }
    }

    &.has-top-bar {
        margin-top: 0;
    }

    .logo-wrap {
        margin-left: auto;

        img {
            height: 60px;
            width: auto;
            margin-top: 5px;
        }
    }

    .title-wrap {
        display: flex;
        align-items: center;
        .title {
            font-size: 24px;
            line-height: 1.2;
        }
        .description {
            line-height: 1.2;
        }
    }

    .bookmark-wrap {
        .icon {
            background: $brand-light-color;
            padding: 0.3rem 0.7rem;
            color: $white;
            font-size: 20px;
            margin-left: 0.5rem;
        }
    }

    .left-wrap {
        a {
            color: $light-green;
        }
    }

    .btn-wrap {
        position: absolute;
        top: .5rem;
        right: 1rem;

        &.left {
            left: 1rem;
            right: auto;
        }

        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.report-damage {
        background: url("../../../images/web/report-bg.png") no-repeat center;
        background-size: cover;
        height: 350px;
    }

    &.guarantee-show {
        .title {
            line-height: 1.125;
        }
    }
}

.message-container {
    background: $white;

    &.title-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5rem 1rem 3rem 1rem;
        position: relative;

        &.is-success {
            background-color: $message-success-bg;
        }

        &.is-error {
            background-color: $message-error-bg;
        }

        .logo-wrap {
            position: absolute;
            top: 3rem;
            right: 5rem;
            img {
                width: 180px;
            }
        }

        @media(max-width: 768px){
            .logo-wrap {
                top: 1rem;
                right: 2rem;
                img {
                    width: 80px;
                }
            }
        }
    }

    &.desc-wrap {
        min-height: 200px;
        padding: 3rem 1rem;

        .btn-wrap {
            margin: 3rem 0 4rem;
            text-align: center;
        }
    }
}

.heading-container {
    background: $white;
    padding: 1rem $container-padding-x;

    .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.2;
    }

    .notify {
        display: flex;
        cursor: pointer;

        .icon-wrap {
            position: relative;

            .icon {
                width: 20px;
            }
        }

        .counter {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #02FD68;
            min-width: 15px;
            height: 15px;
            font-size: 10px;
            border-radius: 20px;
        }
    }
}

.overview-container {
    background: $white;

    &.is-success {
        background: $tool-box-bg-success;
    }

    &.is-locked {
        background: $tool-box-bg-locked;
    }
    &.is-canceled {
        background: $tool-box-bg-canceled;
    }
    &.is-failed {
        background: $tool-box-bg-failed;
    }
}

.content-container {
    padding: 2rem 1rem;
    background: $white;

    .title-wrap {
        .title {
            font-size: 24px;
            font-weight: 600;
            line-height: 1.2;
        }
    }

    .logo-wrap {
        margin-left: auto;

        img {
            height: 60px;
            width: auto;
            margin-top: 5px;
        }
    }
    .bookmark-wrap {
        margin-left: auto;
        text-align: right;
        color: $primary-link-color;
        font-weight: 600;
        text-decoration: none;

        &:hover {
            color: $primary-link-hover-color;
        }

        .icon {
            display: flex;
            align-items: center;

            i {
                margin-right: .25rem;
                margin-bottom: -2px;
            }
        }
    }

    .guarantee-total {
        padding: 2rem 1rem;
        background: #d8e3d2;
    }

    &.product-catalog-container {
        padding: 1rem 0;
    }

    .edit-icon-wrap {
        position: absolute;
        right: 5rem;
        top: 3rem;
    }
    .component-summary {
        position: relative;
        .edit-icon-wrap {
            right: 0;
            top: 0;
        }
    }
}

$custom-hsw-border-color: #E4E2DC;
.search-container {
    padding: 2rem 5rem;

    &.is-primary {
        background: $primary;
    }

    .input-group {
        width: 100%;
        height: 40px;

        .form-control {
            border-radius: 0;
            border-color: $custom-hsw-border-color;
            height: 100%;

            &:focus {
                border-color: #858585;
                box-shadow: none;
            }
        }

        .btn {
            width: 40px;
            border-radius: 0;
            background: #F4F3F2;
            color: $black;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.5rem;
            border: 1px solid $custom-hsw-border-color;
            border-left: 0;
            cursor: pointer;
            padding-top: calc((40px - 36px) / 2);
            padding-left: calc((40px - 36px) / 2);
            padding-right: 0;
            padding-bottom: 0;

            &:focus {
                box-shadow: none;
            }

            &:hover {
                background-color: #dbdad9;
            }
        }
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-style: normal;
        line-height: 1rem;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-style: italic;
        line-height: 1rem;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        font-style: italic;
        line-height: 1rem;
    }
}

.filter-container {
    padding: 2rem 5rem;

    &.is-primary {
        background: $primary;
    }

    .filter-select-hsw {
        position: relative;
        display: block;
        width: 100%;
        height: 40px;
        margin: 0 auto;
        background-color: $white;
        z-index: 0;

        //&:before {
        //    content: "";
        //    position: absolute;
        //    right: 0;
        //    top: 0;
        //    width: 40px;
        //    height: 100%;
        //    border-left: 1px solid $custom-hsw-border-color;
        //    z-index: -1;
        //}


        &:not(.form-date):after { /* triangle: width 13px, heigh 9px */
            content: "";
            position: absolute;
            right: calc((40px - 13px) / 2);
            top: calc((40px - 9px) / 2);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 9px 6.5px 0 6.5px;
            border-color: $dark transparent transparent transparent;
            z-index: -1;
        }

        .form-control {
            border: 1px solid $custom-hsw-border-color;
            outline: none;
            background: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0;
            margin: 0;
            display: block;
            width: 100%;
            height: 100%;
            padding: 0 55px 0 15px;
            color: $dark;

            &:focus {
                border-color: #858585;
                box-shadow: none;
            }
        }
        &:not(.form-date) .form-control{
            cursor: pointer;
        }
        &.form-date .form-control{
            padding: 0 10px 0 15px;
        }
    }
}

.btn-container {
    background: $dark;
    height: 40px;
    padding: 0 1rem;

    &.top {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 1;
    }

    .page-back {
        color: $white;
    }

    &.bottom {
        height: auto;
        position: sticky;
        bottom: 60px;
        z-index: 1;
        background: $white;
        padding: 1rem;
        box-shadow: 0 0 10px -5px $black;
        clip-path: inset(-100% 0 0 0);

        &.scroll {
            box-shadow: none;
        }
    }
}

.tab-container {
    .tabs {
        display: flex;

       .tab {
            padding: 1.25rem 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: $brand-color;
            color: $black;
            line-height: 1;
            transition: background-color 0.2s ease-in-out;

            &.disabled {
                pointer-events: none;
                opacity: .4;
            }

            &:not(:last-child) {
                border-right: .25rem solid $body-bg;
            }

            .number {
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto .25rem;
                background-color: $dark;
                color: $white;
            }

            &:hover,
            &.active {
                background: $white;
                color: $black;
                text-decoration: none;

                &:hover .number {
                    border-color: $black;
                }
            }
        }
    }
}

.tool-container {
    background: $white;
    padding: 1rem;
    position: relative;

    &.is-primary {
        background: $primary;
    }

    .title-wrap {
        margin-bottom: 2rem;

        .title, h1 {
            font-size: 24px;
            line-height: 1.2;
        }

        .collapse-btn {
            position: relative;
            width: 1.5rem;
            height: 1.5rem;
            color: $black;
            display: flex;
            align-items: center;
            justify-content: center;

            &::after {
                font-family: "bootstrap-icons";
                content: '\F64D';
                position: absolute;
                font-size: 1.5rem;
                transition: transform .3s ease-in-out;
                -ms-transition: -ms-transform .3s ease-in-out;
                -webkit-transition: -webkit-transform .3s ease-in-out;
            }

            &[aria-expanded="true"]::after {
                transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }
        }
    }

    .logo-wrap {
        margin-left: auto;
        display: flex;
        justify-content: flex-end;

        img {
            height: 60px;
            width: auto;
            margin-top: 5px;
        }
    }

    .accordion {
        .accordion-item {
            background-color: $bg-color;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            .header {
                display: flex;

                .collapse-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 1.5rem;
                    height: 1.5rem;
                    position: relative;
                    color: $black;
                    margin-left: auto;

                    &::after {
                        font-family: "bootstrap-icons";
                        content: '\F64D';
                        position: absolute;
                        font-size: 1.5rem;
                        transition: transform .3s ease-in-out;
                        -ms-transition: -ms-transform .3s ease-in-out;
                        -webkit-transition: -webkit-transform .3s ease-in-out;
                    }

                    &[aria-expanded="true"]::after {
                        transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                    }
                }
            }

        }
    }
}

.alert-container {
    padding: 0 3rem;
    background-color: $alert-bg;
    font-weight: 600;
    margin-top: .5rem;

    .title {
        margin-bottom: 2rem;
    }

    .description {
        font-size: 16px;
        width: 75%;
        margin-bottom: 3rem;
    }

    .btn-wrap {
        text-align: center;

        .icon {
            width: 18px;
        }
    }
}

.customer-alert-container {
    display: flex;
    justify-content: center;
    padding: $container-padding;
    background-color: $white;
    font-weight: $font-weight-medium;
    font-size: 20px;
}

.info-container {
    padding: 3rem 4rem;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    margin: .5rem 0 1rem;

    &.is-primary {
        background-color: $info-box-primary-bg;
    }

    &.is-waring {
        background-color: $warning-color;
    }
    &.is-danger {
        background-color: $danger-color;
    }
    &.text-normal-weight {
        font-weight: normal;
        .w-100 {
            padding-left: 25px;
            margin-bottom: 0;
        }
    }
}
.actions-wrapper {
    display: flex;
    align-items: center;
    .btn {
        margin: 32px auto 0;
        display: flex;
        align-items: center;
        img {
            margin-right: 4px;
        }
    }
    &.success {
        flex-direction: row;
        align-items: center;
        .btn {
            margin: 32px auto;
            max-width: 33%;
            &:first-child { margin-right: 15px; }
            &:last-child { margin-left: 15px; }
        }
    }
}

.hero-container {
    background: $white;
    padding: 3rem $container-padding-x;
    position: relative;
    background: $primary;

    .title-wrap {
        margin-bottom: 2rem;
    }

    .logo-wrap {
        text-align: center;

        .logo {
            //width: 200px;
            width: auto;
            height: 60px;
        }
    }

    .btn-wrap {
        text-align: center;

        .btn {
            padding-right: 4rem;
            padding-left: 4rem;
        }
    }
}

.hero-btn-container {
    margin: 0 0 .5rem 0;

    .btn-wrap {
        font-size: 20px;
        font-weight: 600;
        color: $cc-button-tabs-color;
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $cc-button-tabs-background;
        cursor: pointer;

        i {
            --icon-size: 35px;
            background-color: $cc-button-tabs-color;
        }

        &:hover {
            background-color: $cc-button-tabs-hover;
            color: $cc-button-tabs-hover-color;
            i {
                background-color: $cc-button-tabs-hover-color;
            }
        }

        &:not(:last-child) {
            border-right: 1px solid $bg-color;
        }

        .icon-wrap {
            display: flex;
            align-items: center;
            margin-right: 1rem;
        }
        &.disabled {
            pointer-events: none;
            opacity: 1;
            color: $cc-button-tabs-color-disabled;

            i {
                background-color: $cc-button-tabs-color-disabled;
            }
        }
    }
}

.tool-tip-container {
    display: flex;
    justify-content: flex-end;
    position: relative;

    .show-tool-tip {
        cursor: pointer;
        margin-right: 0.5rem !important;
    }

    .tool-tip-info-wrap {
        background: #ffffff;
        width: 250px;
        padding: 1rem;
        z-index: 999;
        top: 1.25rem;
        box-shadow: 0 3px 6px #00000029;
        position: absolute;
        font-size: 14px;

        //.triangle-up {
        //    position: absolute;
        //    width: 0;
        //    height: 0;
        //    border-left: 15px solid transparent;
        //    border-right: 15px solid transparent;
        //    border-bottom: 20px solid #eeeeee;
        //    top: -21px;
        //    right: 70px;
        //}

        .close-tool-tip {
            cursor: pointer;
        }
    }
}

@include media-breakpoint-up(md) {
    .title-container {
        padding: 1rem $container-padding-x;
        //flex-direction: row;

        .left-wrap {
            margin-top: 0;
            margin-left: auto;

            a {
                color: $black;
            }
        }

        .btn-wrap {
            &.left {
                left: 2rem;
                right: auto;
            }
        }

        &.guarantee-show {
            padding: 38px;

            .title {
                line-height: 1.25;
            }
        }
    }

    .search-container {
        padding: 3rem 5rem;
    }

    .filter-container {
        padding: 3rem 5rem;
    }

    .content-container {
        padding: 3rem $container-padding-x;
    }

    .container-nest {
        padding: 0 4rem;
    }

    .btn-container {
        background: $white;
        height: auto;
        padding: 2rem 10rem 0 2rem;

        &.top {
            position: relative;
        }

        .page-back {
            color: $black;
        }

        .tool {
            .tool-item {
                width: auto;
                border-left: 0;
                color: $black;
                padding-left: 1rem;
            }
        }

        &.bottom {
            bottom: 0;
        }
    }

    .tool-container {
        padding: 3rem $container-padding-x;

        .btn-wrap {
            position: absolute;
            top: .75rem;
            right: 1.5rem;

            &.left {
                left: 1rem;
                right: auto;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .tool-list {
        padding: 2rem 2rem !important;
    }
}

.legal-container {
    .title-wrap {
        background-color: $web-title-bg;
        padding: 2rem 3rem;
        display: flex;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: .5rem;
    }

    .content-wrap {
        padding: 2rem 3rem;
        background-color: $white;
        min-height: 300px;
    }
}

.template-header-container {
    background-color: white;
    padding: 3rem 4rem;
    @media (max-width: 768px) {
        padding: 25px;
    }
    position: relative;
    border-bottom: 2px solid #F4F3F2;

    .search-wrap {
        display: flex;
        justify-content: center;
    }
}
.plr-4r {
    padding-left: 4rem;
    padding-right: 4rem;
}
.template-header-container.yellow {
    background: #FFFED9;
    .title-wrap {
        color: #252928;
        display: flex;
        align-items: center;
        h1 { font-size: 16px; margin-bottom: 0; }
        img { margin-right: 5px; }
    }
    .sub-title-wrap {
        padding-left: 25px;
        padding-top: 15px;
        p { margin-bottom: 0; }
        .btn {
            font-weight: normal;
            margin: 0 !important; height: auto;
            padding: 0;
            font-size: 16px;
        }
    }
}

form {
    .form-group {
        label {
            font-size: 13px;
        }
    }
    .is-primary {
        .svg-icon-link {
            margin-left: 5px;
        }
        path {
            fill: $primary-link-color;
        }
        &:hover {
            path {
                fill: $primary-link-hover-color;
            }
        }
    }
}
.ml0 { margin-left: 0 !important; }
.mr0 { margin-right: 0 !important; }
.invoice-name {
    display: flex;
    align-items: center;
    img {
        margin-right: 8px;
    }
}
.cc-link {
    &.with-svg {
        display: flex;
        align-items: center;
        img {
            margin-right: 4px;
        }
        &:hover {
            img {
                opacity: 0.5;
            }
        }
    }
}
.media-row:nth-child(2n+1) {
    background: #F6F6F4;
    padding: 3px 0 !important;
    margin-bottom: 3px;
}
.content-container {
    &.yellow { background: #FFFED9; }
}
.info-wrap {
    display: flex;
    align-items: center;

    p {
        margin: 0 0 0 5px;
    }
}
.summary-row {
    display: flex;
}
.yellow {
    background: #FFFED9;
    justify-content: left;
}
.show-card {
    .blade-show {
        margin-bottom: 0;
        align-items: center;
        .logo-wrap {
            margin-left: auto;
            display: flex;
            justify-content: flex-end;
            img {
                height: 60px !important;
                width: auto;
                margin-top: 5px;
            }
        }
    }
    .card-head {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: .25rem;
    }
    .preis-columns {
        padding-left: $container-padding-x;
    }
    .btn-link {
        &.btn-edit {
            font-weight: 600;
            color: $cc-link-color;
            cursor: pointer;
            text-decoration: none;
            img {
                filter: invert(0.6) sepia(1) saturate(100) hue-rotate(190deg) opacity(0.6);
            }
            &:hover {
                color: $cc-link-hover-color;
                img {
                    filter: invert(0.6) sepia(1) saturate(100) hue-rotate(190deg) opacity(0.4);
                }
            }
        }
    }
    .btn {
        border: none;
        &.btn-download {
            img { filter: invert(1) brightness(0) opacity(0.65); }
        }
        &:hover { border: none; }
    }
}

.btn-link img{
    filter: invert(65%) sepia(10%) saturate(1997%) hue-rotate(148deg) brightness(91%) contrast(82%);
}
.btn-link:hover img,
.btn-link img:hover{
    filter: invert(85%) sepia(17%) saturate(872%) hue-rotate(161deg) brightness(92%) contrast(88%);
}

.no-border {
    border: none !important;
    &:hover { border: none !important; }
}
.fs-16 { font-size: 16px; }
#app { overflow-x: hidden; }
