// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$brand-color-admin: #99a594;

$hsw-button-primary: #2BFD68;
$hsw-button-primary-border: #2BFD68;
$hsw-button-primary-hover: #26E86C;
$hsw-button-primary-hover-border: #25E46A;

$hsw-button-secondary: #EBEBEB;
$hsw-button-secondary-border: #252928;
$hsw-button-secondary-hover: #FFFFFF;
$hsw-button-secondary-hover-border: #252928;
