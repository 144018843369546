.logo-rec {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    &__gira {
        img {
            width: 70%;
            height: auto;
        }
    }

    &__jung {
        img {
            width: 70%;
            height: auto;
        }
    }

    &__brumberg {
        img {
            width: 90%;
            height: auto;
        }
    }
}
.manufacturer-image {
    margin: 0;
    padding: 0px !important;
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    @media (min-width: 768px) {
        padding: 15px !important;
        flex: 0 0 20%;
    }
    img {
        width: 100%;
        height: auto;
        @media (max-width: 768px) {
            display: block;
            margin: 0 auto;
            width: 50%;
        }
    }
}
.list-unstyled {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
