body {
    font-family: "obvia", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    color: $body-text-color;
    position: relative;
    min-height: 100vh;
    background: $body-bg;
    line-height: 1.3;

    h1, h2, h3, h4, h5, h6 {
        font-weight: 600 !important;
        margin-bottom: 0;
    }

    h1 {
        font-size: $h1-font-size;
    }

    h3 {
        font-size: $h3-font-size;
    }

    .font-weight-bold {
        font-weight: 600 !important;
    }

    .logo-font {
        font-family: 'hws-font';
    }

    #app {
        min-height: 100vh;

        .vh-full {
            min-height: calc(100vh - 155px);
        }

        main {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: 0;
        transition: opacity .5s;
    }

    &.menu-open::before {
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: 9999 !important;
    }

    a {
        color: $global-link-color;
        font-weight: 600;

        &:hover,
        :hover {
            color: $global-link-hover-color;
            text-decoration: none;
        }

        &.has-underline-link {
            text-decoration: underline;
        }

        &.disabled {
            pointer-events: none;
            opacity: .4;
        }

        &.is-primary {
            color: $primary-link-color;
            font-weight: 600;
            text-decoration: none;

            &:hover {
                color: $primary-link-hover-color !important;
            }
        }
    }

    .link-wrap {
        &.is-primary {
            color: $primary-link-color;
            font-weight: 600;
            text-decoration: none;

            .svg-icon-link {
                fill: $primary-link-color;
            }

            &:hover {
                color: $primary-link-hover-color;

                .svg-icon-link {
                    fill: $primary-link-hover-color;
                }
            }
        }
    }

    .body-bg {
        background-color: $body-bg
    }

    .cursor-pointer {
        cursor: pointer !important;
    }

    .disabled {
        pointer-events: none;
        opacity: .4;
    }

    .text-blue {
        color: $primary-link-color;
    }
}

@media (max-width: 991px) {
    h1 {
        font-size: $font-size-base;
    }

    h3 {
        font-size: $font-size-base;
    }
}
