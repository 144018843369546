/* Border */
.hr-pyr {
    $border-color: #E5E5E5;
    border-bottom: 1px solid $border-color;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 1rem;

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -10px;
        width: 20px;
        height: 20px;
        left: 48%;
        background: $white;
        border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        transform: rotate(224deg);
    }
}
