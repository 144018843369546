@charset "UTF-8";
@import "../fonts.css";
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import '~@fortawesome/fontawesome-free/css/all.css';
@import "~bootstrap/dist/css/bootstrap.css";
@import '~pikaday/css/pikaday.css';
@import url("https://p.typekit.net/p.css?s=1&k=hay7hzk&ht=tk&f=39773.39780.39783&a=5309578&app=typekit&e=css");
:root {
  --blue: #3490dc;
  --indigo: #6574cd;
  --purple: #9561e2;
  --pink: #f66d9b;
  --red: #e3342f;
  --orange: #f6993f;
  --yellow: #ffed4a;
  --green: #38c172;
  --teal: #4dc0b5;
  --cyan: #6cb2eb;
  --white: #fff;
  --gray: #858796;
  --gray-dark: #5a5c69;
  --primary: #3490dc;
  --secondary: #858796;
  --success: #38c172;
  --info: #6cb2eb;
  --warning: #ffed4a;
  --danger: #e3342f;
  --light: #f8f9fc;
  --dark: #5a5c69;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Nunito", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #858796;
  text-align: left;
  background-color: #f8fafc;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #3490dc;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #1d68a7;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #858796;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 1.8rem;
}

h3, .h3 {
  font-size: 1.575rem;
}

h4, .h4 {
  font-size: 1.35rem;
}

h5, .h5 {
  font-size: 1.125rem;
}

h6, .h6 {
  font-size: 0.9rem;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #858796;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f8fafc;
  border: 1px solid #dddfeb;
  border-radius: 0.35rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #858796;
}

code {
  font-size: 87.5%;
  color: #f66d9b;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #3a3b45;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #3a3b45;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #858796;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e3e6f0;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e3e6f0;
}
.table tbody + tbody {
  border-top: 2px solid #e3e6f0;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e3e6f0;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #e3e6f0;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #858796;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c6e0f5;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #95c5ed;
}

.table-hover .table-primary:hover {
  background-color: #b0d4f1;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b0d4f1;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddde2;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #c0c1c8;
}

.table-hover .table-secondary:hover {
  background-color: #cfcfd6;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfcfd6;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c7eed8;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #98dfb6;
}

.table-hover .table-success:hover {
  background-color: #b3e8ca;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b3e8ca;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d6e9f9;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #b3d7f5;
}

.table-hover .table-info:hover {
  background-color: #c0ddf6;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c0ddf6;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fffacc;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fff6a1;
}

.table-hover .table-warning:hover {
  background-color: #fff8b3;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fff8b3;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f7c6c5;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f09593;
}

.table-hover .table-danger:hover {
  background-color: #f4b0af;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f4b0af;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfd;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #d1d1d5;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #a9aab1;
}

.table-hover .table-dark:hover {
  background-color: #c4c4c9;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #c4c4c9;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #5a5c69;
  border-color: #6c6e7e;
}
.table .thead-light th {
  color: #6e707e;
  background-color: #eaecf4;
  border-color: #e3e6f0;
}

.table-dark {
  color: #fff;
  background-color: #5a5c69;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #6c6e7e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #6e707e;
}
.form-control:focus {
  color: #6e707e;
  background-color: #fff;
  border-color: #a1cbef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.form-control::placeholder {
  color: #858796;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #eaecf4;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #6e707e;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.125rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.7875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.9rem;
  line-height: 1.6;
  color: #858796;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #858796;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #38c172;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.7875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(56, 193, 114, 0.9);
  border-radius: 0.35rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #38c172;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338c172' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #38c172;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%235a5c69' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2338c172' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #38c172;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #38c172;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #38c172;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5cd08d;
  background-color: #5cd08d;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #38c172;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #38c172;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #38c172;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e3342f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.7875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(227, 52, 47, 0.9);
  border-radius: 0.35rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e3342f;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e3342f;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%235a5c69' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e3342f;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e3342f;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e3342f;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e9605c;
  background-color: #e9605c;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e3342f;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e3342f;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #858796;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.6;
  border-radius: 0.35rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #858796;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.btn-primary:hover {
  color: #fff;
  background-color: #227dc7;
  border-color: #2176bd;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #227dc7;
  border-color: #2176bd;
  box-shadow: 0 0 0 0.2rem rgba(82, 161, 225, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2176bd;
  border-color: #1f6fb2;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 161, 225, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #858796;
  border-color: #858796;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #717384;
  border-color: #6b6d7d;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #717384;
  border-color: #6b6d7d;
  box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #858796;
  border-color: #858796;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6b6d7d;
  border-color: #656776;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}
.btn-success:hover {
  color: #fff;
  background-color: #2fa360;
  border-color: #2d995b;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #2fa360;
  border-color: #2d995b;
  box-shadow: 0 0 0 0.2rem rgba(86, 202, 135, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #2d995b;
  border-color: #2a9055;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 202, 135, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}
.btn-info:hover {
  color: #fff;
  background-color: #4aa0e6;
  border-color: #3f9ae5;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #4aa0e6;
  border-color: #3f9ae5;
  box-shadow: 0 0 0 0.2rem rgba(130, 190, 238, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #3f9ae5;
  border-color: #3495e3;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 190, 238, 0.5);
}

.btn-warning {
  color: #3a3b45;
  background-color: #ffed4a;
  border-color: #ffed4a;
}
.btn-warning:hover {
  color: #3a3b45;
  background-color: #ffe924;
  border-color: #ffe817;
}
.btn-warning:focus, .btn-warning.focus {
  color: #3a3b45;
  background-color: #ffe924;
  border-color: #ffe817;
  box-shadow: 0 0 0 0.2rem rgba(225, 210, 73, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #3a3b45;
  background-color: #ffed4a;
  border-color: #ffed4a;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #3a3b45;
  background-color: #ffe817;
  border-color: #ffe70a;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 210, 73, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}
.btn-danger:hover {
  color: #fff;
  background-color: #d0211c;
  border-color: #c51f1a;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #d0211c;
  border-color: #c51f1a;
  box-shadow: 0 0 0 0.2rem rgba(231, 82, 78, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c51f1a;
  border-color: #b91d19;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(231, 82, 78, 0.5);
}

.btn-light {
  color: #3a3b45;
  background-color: #f8f9fc;
  border-color: #f8f9fc;
}
.btn-light:hover {
  color: #3a3b45;
  background-color: #dde2f1;
  border-color: #d4daed;
}
.btn-light:focus, .btn-light.focus {
  color: #3a3b45;
  background-color: #dde2f1;
  border-color: #d4daed;
  box-shadow: 0 0 0 0.2rem rgba(220, 221, 225, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #3a3b45;
  background-color: #f8f9fc;
  border-color: #f8f9fc;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #3a3b45;
  background-color: #d4daed;
  border-color: #cbd3e9;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 221, 225, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #5a5c69;
  border-color: #5a5c69;
}
.btn-dark:hover {
  color: #fff;
  background-color: #484a54;
  border-color: #42444e;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #484a54;
  border-color: #42444e;
  box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #5a5c69;
  border-color: #5a5c69;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #42444e;
  border-color: #3d3e47;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
}

.btn-outline-primary {
  color: #3490dc;
  border-color: #3490dc;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #3490dc;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}

.btn-outline-secondary {
  color: #858796;
  border-color: #858796;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #858796;
  border-color: #858796;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #858796;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #858796;
  border-color: #858796;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
}

.btn-outline-success {
  color: #38c172;
  border-color: #38c172;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #38c172;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #38c172;
  border-color: #38c172;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.btn-outline-info {
  color: #6cb2eb;
  border-color: #6cb2eb;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #6cb2eb;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #6cb2eb;
  border-color: #6cb2eb;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}

.btn-outline-warning {
  color: #ffed4a;
  border-color: #ffed4a;
}
.btn-outline-warning:hover {
  color: #3a3b45;
  background-color: #ffed4a;
  border-color: #ffed4a;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffed4a;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #3a3b45;
  background-color: #ffed4a;
  border-color: #ffed4a;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}

.btn-outline-danger {
  color: #e3342f;
  border-color: #e3342f;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #e3342f;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e3342f;
  border-color: #e3342f;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}

.btn-outline-light {
  color: #f8f9fc;
  border-color: #f8f9fc;
}
.btn-outline-light:hover {
  color: #3a3b45;
  background-color: #f8f9fc;
  border-color: #f8f9fc;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fc;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #3a3b45;
  background-color: #f8f9fc;
  border-color: #f8f9fc;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
}

.btn-outline-dark {
  color: #5a5c69;
  border-color: #5a5c69;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #5a5c69;
  border-color: #5a5c69;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 92, 105, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #5a5c69;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #5a5c69;
  border-color: #5a5c69;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(90, 92, 105, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #3490dc;
  text-decoration: none;
}
.btn-link:hover {
  color: #1d68a7;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #858796;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.85rem;
  color: #858796;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #eaecf4;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #3a3b45;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #2e2f37;
  text-decoration: none;
  background-color: #eaecf4;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #3490dc;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #b7b9cc;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.7875rem;
  color: #858796;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #3a3b45;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #6e707e;
  text-align: center;
  white-space: nowrap;
  background-color: #eaecf4;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.44rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.22rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #3490dc;
  background-color: #3490dc;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #a1cbef;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #cce3f6;
  border-color: #cce3f6;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #858796;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #eaecf4;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.22rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #b7b9cc solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.22rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.35rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3490dc;
  background-color: #3490dc;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.22rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #b7b9cc;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(52, 144, 220, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.6;
  color: #6e707e;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%235a5c69' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #a1cbef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.custom-select:focus::-ms-value {
  color: #6e707e;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #858796;
  background-color: #eaecf4;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #6e707e;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.7875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.125rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #a1cbef;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #eaecf4;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.6;
  color: #6e707e;
  background-color: #fff;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.6em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.6;
  color: #6e707e;
  content: "Browse";
  background-color: #eaecf4;
  border-left: inherit;
  border-radius: 0 0.35rem 0.35rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #3490dc;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #cce3f6;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dddfeb;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #3490dc;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #cce3f6;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dddfeb;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #3490dc;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #cce3f6;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dddfeb;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dddfeb;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #b7b9cc;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #b7b9cc;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #b7b9cc;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #858796;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dddfeb;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #eaecf4 #eaecf4 #dddfeb;
}
.nav-tabs .nav-link.disabled {
  color: #858796;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #6e707e;
  background-color: #f8fafc;
  border-color: #dddfeb #dddfeb #f8fafc;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.35rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3490dc;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.32rem;
  padding-bottom: 0.32rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.35rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.35rem - 1px);
  border-top-right-radius: calc(0.35rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.35rem - 1px);
  border-bottom-left-radius: calc(0.35rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f8f9fc;
  border-bottom: 1px solid #e3e6f0;
}
.card-header:first-child {
  border-radius: calc(0.35rem - 1px) calc(0.35rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #f8f9fc;
  border-top: 1px solid #e3e6f0;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.35rem - 1px) calc(0.35rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.35rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.35rem - 1px);
  border-top-right-radius: calc(0.35rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.35rem - 1px);
  border-bottom-left-radius: calc(0.35rem - 1px);
}

.card-deck .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 0.75rem;
    margin-bottom: 0;
    margin-left: 0.75rem;
  }
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #eaecf4;
  border-radius: 0.35rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #858796;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #858796;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.35rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3490dc;
  background-color: #fff;
  border: 1px solid #dddfeb;
}
.page-link:hover {
  z-index: 2;
  color: #1d68a7;
  text-decoration: none;
  background-color: #eaecf4;
  border-color: #dddfeb;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.page-item.disabled .page-link {
  color: #858796;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dddfeb;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.7875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.35rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #3490dc;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #2176bd;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #858796;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #6b6d7d;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #38c172;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #2d995b;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(56, 193, 114, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #6cb2eb;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #3f9ae5;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 178, 235, 0.5);
}

.badge-warning {
  color: #3a3b45;
  background-color: #ffed4a;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #3a3b45;
  background-color: #ffe817;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 237, 74, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #e3342f;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #c51f1a;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.5);
}

.badge-light {
  color: #3a3b45;
  background-color: #f8f9fc;
}
a.badge-light:hover, a.badge-light:focus {
  color: #3a3b45;
  background-color: #d4daed;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #5a5c69;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #42444e;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(90, 92, 105, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #eaecf4;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.35rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.85rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #1b4b72;
  background-color: #d6e9f8;
  border-color: #c6e0f5;
}
.alert-primary hr {
  border-top-color: #b0d4f1;
}
.alert-primary .alert-link {
  color: #113049;
}

.alert-secondary {
  color: #45464e;
  background-color: #e7e7ea;
  border-color: #dddde2;
}
.alert-secondary hr {
  border-top-color: #cfcfd6;
}
.alert-secondary .alert-link {
  color: #2d2e33;
}

.alert-success {
  color: #1d643b;
  background-color: #d7f3e3;
  border-color: #c7eed8;
}
.alert-success hr {
  border-top-color: #b3e8ca;
}
.alert-success .alert-link {
  color: #123c24;
}

.alert-info {
  color: #385d7a;
  background-color: #e2f0fb;
  border-color: #d6e9f9;
}
.alert-info hr {
  border-top-color: #c0ddf6;
}
.alert-info .alert-link {
  color: #284257;
}

.alert-warning {
  color: #857b26;
  background-color: #fffbdb;
  border-color: #fffacc;
}
.alert-warning hr {
  border-top-color: #fff8b3;
}
.alert-warning .alert-link {
  color: #5d561b;
}

.alert-danger {
  color: #761b18;
  background-color: #f9d6d5;
  border-color: #f7c6c5;
}
.alert-danger hr {
  border-top-color: #f4b0af;
}
.alert-danger .alert-link {
  color: #4c110f;
}

.alert-light {
  color: #818183;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686869;
}

.alert-dark {
  color: #2f3037;
  background-color: #dedee1;
  border-color: #d1d1d5;
}
.alert-dark hr {
  border-top-color: #c4c4c9;
}
.alert-dark .alert-link {
  color: #18181c;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.675rem;
  background-color: #eaecf4;
  border-radius: 0.35rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3490dc;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.35rem;
}

.list-group-item-action {
  width: 100%;
  color: #6e707e;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #6e707e;
  text-decoration: none;
  background-color: #f8f9fc;
}
.list-group-item-action:active {
  color: #858796;
  background-color: #eaecf4;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #858796;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.35rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.35rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.35rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.35rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.35rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.35rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.35rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.35rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.35rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.35rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1b4b72;
  background-color: #c6e0f5;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #1b4b72;
  background-color: #b0d4f1;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #1b4b72;
  border-color: #1b4b72;
}

.list-group-item-secondary {
  color: #45464e;
  background-color: #dddde2;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #45464e;
  background-color: #cfcfd6;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #45464e;
  border-color: #45464e;
}

.list-group-item-success {
  color: #1d643b;
  background-color: #c7eed8;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #1d643b;
  background-color: #b3e8ca;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1d643b;
  border-color: #1d643b;
}

.list-group-item-info {
  color: #385d7a;
  background-color: #d6e9f9;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #385d7a;
  background-color: #c0ddf6;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #385d7a;
  border-color: #385d7a;
}

.list-group-item-warning {
  color: #857b26;
  background-color: #fffacc;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #857b26;
  background-color: #fff8b3;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #857b26;
  border-color: #857b26;
}

.list-group-item-danger {
  color: #761b18;
  background-color: #f7c6c5;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #761b18;
  background-color: #f4b0af;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #761b18;
  border-color: #761b18;
}

.list-group-item-light {
  color: #818183;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818183;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818183;
  border-color: #818183;
}

.list-group-item-dark {
  color: #2f3037;
  background-color: #d1d1d5;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #2f3037;
  background-color: #c4c4c9;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #2f3037;
  border-color: #2f3037;
}

.close {
  float: right;
  font-size: 1.35rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #858796;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e3e6f0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e3e6f0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.7875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.35rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.7875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #858796;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #3490dc !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2176bd !important;
}

.bg-secondary {
  background-color: #858796 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #6b6d7d !important;
}

.bg-success {
  background-color: #38c172 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #2d995b !important;
}

.bg-info {
  background-color: #6cb2eb !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #3f9ae5 !important;
}

.bg-warning {
  background-color: #ffed4a !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffe817 !important;
}

.bg-danger {
  background-color: #e3342f !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c51f1a !important;
}

.bg-light {
  background-color: #f8f9fc !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d4daed !important;
}

.bg-dark {
  background-color: #5a5c69 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #42444e !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e3e6f0 !important;
}

.border-top {
  border-top: 1px solid #e3e6f0 !important;
}

.border-right {
  border-right: 1px solid #e3e6f0 !important;
}

.border-bottom {
  border-bottom: 1px solid #e3e6f0 !important;
}

.border-left {
  border-left: 1px solid #e3e6f0 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3490dc !important;
}

.border-secondary {
  border-color: #858796 !important;
}

.border-success {
  border-color: #38c172 !important;
}

.border-info {
  border-color: #6cb2eb !important;
}

.border-warning {
  border-color: #ffed4a !important;
}

.border-danger {
  border-color: #e3342f !important;
}

.border-light {
  border-color: #f8f9fc !important;
}

.border-dark {
  border-color: #5a5c69 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.35rem !important;
}

.rounded-top {
  border-top-left-radius: 0.35rem !important;
  border-top-right-radius: 0.35rem !important;
}

.rounded-right {
  border-top-right-radius: 0.35rem !important;
  border-bottom-right-radius: 0.35rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.35rem !important;
  border-bottom-left-radius: 0.35rem !important;
}

.rounded-left {
  border-top-left-radius: 0.35rem !important;
  border-bottom-left-radius: 0.35rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(58, 59, 69, 0.2) !important;
}

.shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase, .sidebar .sidebar-heading, .dropdown .dropdown-menu .dropdown-header {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #3490dc !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #1d68a7 !important;
}

.text-secondary {
  color: #858796 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #60616f !important;
}

.text-success {
  color: #38c172 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #27864f !important;
}

.text-info {
  color: #6cb2eb !important;
}

a.text-info:hover, a.text-info:focus {
  color: #298fe2 !important;
}

.text-warning {
  color: #ffed4a !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #fde300 !important;
}

.text-danger {
  color: #e3342f !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ae1c17 !important;
}

.text-light {
  color: #f8f9fc !important;
}

a.text-light:hover, a.text-light:focus {
  color: #c2cbe5 !important;
}

.text-dark {
  color: #5a5c69 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #373840 !important;
}

.text-body {
  color: #858796 !important;
}

.text-muted {
  color: #858796 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #b7b9cc;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dddfeb !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e3e6f0;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e3e6f0;
  }
}
html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;
}

a:focus {
  outline: none;
}

#wrapper {
  display: flex;
}
#wrapper #content-wrapper {
  background-color: #f8f9fc;
  width: 100%;
  overflow-x: hidden;
}
#wrapper #content-wrapper #content {
  flex: 1 0 auto;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.scroll-to-top {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: none;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: #fff;
  background: rgba(90, 92, 105, 0.5);
  line-height: 46px;
}
.scroll-to-top:focus, .scroll-to-top:hover {
  color: white;
}
.scroll-to-top:hover {
  background: #5a5c69;
}
.scroll-to-top i {
  font-weight: 800;
}

@keyframes growIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.animated--grow-in, .sidebar .nav-item .collapse {
  animation-name: growIn;
  animation-duration: 200ms;
  animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animated--fade-in {
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
}

.bg-gradient-primary {
  background-color: #3490dc;
  background-image: linear-gradient(180deg, #3490dc 10%, #1d68a7 100%);
  background-size: cover;
}

.bg-gradient-secondary {
  background-color: #858796;
  background-image: linear-gradient(180deg, #858796 10%, #60616f 100%);
  background-size: cover;
}

.bg-gradient-success {
  background-color: #38c172;
  background-image: linear-gradient(180deg, #38c172 10%, #27864f 100%);
  background-size: cover;
}

.bg-gradient-info {
  background-color: #6cb2eb;
  background-image: linear-gradient(180deg, #6cb2eb 10%, #298fe2 100%);
  background-size: cover;
}

.bg-gradient-warning {
  background-color: #ffed4a;
  background-image: linear-gradient(180deg, #ffed4a 10%, #fde300 100%);
  background-size: cover;
}

.bg-gradient-danger {
  background-color: #e3342f;
  background-image: linear-gradient(180deg, #e3342f 10%, #ae1c17 100%);
  background-size: cover;
}

.bg-gradient-light {
  background-color: #f8f9fc;
  background-image: linear-gradient(180deg, #f8f9fc 10%, #c2cbe5 100%);
  background-size: cover;
}

.bg-gradient-dark {
  background-color: #5a5c69;
  background-image: linear-gradient(180deg, #5a5c69 10%, #373840 100%);
  background-size: cover;
}

.bg-gray-100 {
  background-color: #f8f9fc !important;
}

.bg-gray-200 {
  background-color: #eaecf4 !important;
}

.bg-gray-300 {
  background-color: #dddfeb !important;
}

.bg-gray-400 {
  background-color: #d1d3e2 !important;
}

.bg-gray-500 {
  background-color: #b7b9cc !important;
}

.bg-gray-600 {
  background-color: #858796 !important;
}

.bg-gray-700 {
  background-color: #6e707e !important;
}

.bg-gray-800 {
  background-color: #5a5c69 !important;
}

.bg-gray-900 {
  background-color: #3a3b45 !important;
}

.o-hidden {
  overflow: hidden !important;
}

.text-xs {
  font-size: 0.7rem;
}

.text-lg {
  font-size: 1.2rem;
}

.text-gray-100 {
  color: #f8f9fc !important;
}

.text-gray-200 {
  color: #eaecf4 !important;
}

.text-gray-300 {
  color: #dddfeb !important;
}

.text-gray-400 {
  color: #d1d3e2 !important;
}

.text-gray-500 {
  color: #b7b9cc !important;
}

.text-gray-600 {
  color: #858796 !important;
}

.text-gray-700 {
  color: #6e707e !important;
}

.text-gray-800 {
  color: #5a5c69 !important;
}

.text-gray-900 {
  color: #3a3b45 !important;
}

.icon-circle {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-left-primary {
  border-left: 0.25rem solid #3490dc !important;
}

.border-bottom-primary {
  border-bottom: 0.25rem solid #3490dc !important;
}

.border-left-secondary {
  border-left: 0.25rem solid #858796 !important;
}

.border-bottom-secondary {
  border-bottom: 0.25rem solid #858796 !important;
}

.border-left-success {
  border-left: 0.25rem solid #38c172 !important;
}

.border-bottom-success {
  border-bottom: 0.25rem solid #38c172 !important;
}

.border-left-info {
  border-left: 0.25rem solid #6cb2eb !important;
}

.border-bottom-info {
  border-bottom: 0.25rem solid #6cb2eb !important;
}

.border-left-warning {
  border-left: 0.25rem solid #ffed4a !important;
}

.border-bottom-warning {
  border-bottom: 0.25rem solid #ffed4a !important;
}

.border-left-danger {
  border-left: 0.25rem solid #e3342f !important;
}

.border-bottom-danger {
  border-bottom: 0.25rem solid #e3342f !important;
}

.border-left-light {
  border-left: 0.25rem solid #f8f9fc !important;
}

.border-bottom-light {
  border-bottom: 0.25rem solid #f8f9fc !important;
}

.border-left-dark {
  border-left: 0.25rem solid #5a5c69 !important;
}

.border-bottom-dark {
  border-bottom: 0.25rem solid #5a5c69 !important;
}

.progress-sm {
  height: 0.5rem;
}

.rotate-15 {
  transform: rotate(15deg);
}

.rotate-n-15 {
  transform: rotate(-15deg);
}

.dropdown .dropdown-menu {
  font-size: 0.85rem;
}
.dropdown .dropdown-menu .dropdown-header {
  font-weight: 800;
  font-size: 0.65rem;
  color: #b7b9cc;
}

.dropdown.no-arrow .dropdown-toggle::after {
  display: none;
}

.sidebar .nav-item.dropdown .dropdown-toggle::after,
.topbar .nav-item.dropdown .dropdown-toggle::after {
  width: 1rem;
  text-align: center;
  float: right;
  vertical-align: 0;
  border: 0;
  font-weight: 900;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
}
.sidebar .nav-item.dropdown.show .dropdown-toggle::after,
.topbar .nav-item.dropdown.show .dropdown-toggle::after {
  content: "\f107";
}
.sidebar .nav-item .nav-link,
.topbar .nav-item .nav-link {
  position: relative;
}
.sidebar .nav-item .nav-link .badge-counter,
.topbar .nav-item .nav-link .badge-counter {
  position: absolute;
  transform: scale(0.7);
  transform-origin: top right;
  right: 0.25rem;
  margin-top: -0.25rem;
}
.sidebar .nav-item .nav-link .img-profile,
.topbar .nav-item .nav-link .img-profile {
  height: 2rem;
  width: 2rem;
}

.topbar {
  height: 4.375rem;
}
.topbar #sidebarToggleTop {
  height: 2.5rem;
  width: 2.5rem;
}
.topbar #sidebarToggleTop:hover {
  background-color: #eaecf4;
}
.topbar #sidebarToggleTop:active {
  background-color: #dddfeb;
}
.topbar .navbar-search {
  width: 25rem;
}
.topbar .navbar-search input {
  font-size: 0.85rem;
  height: auto;
}
.topbar .topbar-divider {
  width: 0;
  border-right: 1px solid #e3e6f0;
  height: calc(4.375rem - 2rem);
  margin: auto 1rem;
}
.topbar .nav-item .nav-link {
  height: 4.375rem;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
}
.topbar .nav-item .nav-link:focus {
  outline: none;
}
.topbar .nav-item:focus {
  outline: none;
}
.topbar .dropdown {
  position: static;
}
.topbar .dropdown .dropdown-menu {
  width: calc(100% - 1.5rem);
  right: 0.75rem;
}
.topbar .dropdown-list {
  padding: 0;
  border: none;
  overflow: hidden;
}
.topbar .dropdown-list .dropdown-header {
  background-color: #3490dc;
  border: 1px solid #3490dc;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #fff;
}
.topbar .dropdown-list .dropdown-item {
  white-space: normal;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-left: 1px solid #e3e6f0;
  border-right: 1px solid #e3e6f0;
  border-bottom: 1px solid #e3e6f0;
  line-height: 1.3rem;
}
.topbar .dropdown-list .dropdown-item .dropdown-list-image {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
}
.topbar .dropdown-list .dropdown-item .dropdown-list-image img {
  height: 2.5rem;
  width: 2.5rem;
}
.topbar .dropdown-list .dropdown-item .dropdown-list-image .status-indicator {
  background-color: #eaecf4;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  border: 0.125rem solid #fff;
}
.topbar .dropdown-list .dropdown-item .text-truncate {
  max-width: 10rem;
}
.topbar .dropdown-list .dropdown-item:active {
  background-color: #eaecf4;
  color: #3a3b45;
}
@media (min-width: 576px) {
  .topbar .dropdown {
    position: relative;
  }
  .topbar .dropdown .dropdown-menu {
    width: auto;
    right: 0;
  }
  .topbar .dropdown-list {
    width: 20rem !important;
  }
  .topbar .dropdown-list .dropdown-item .text-truncate {
    max-width: 13.375rem;
  }
}

.topbar.navbar-dark .navbar-nav .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.topbar.navbar-dark .navbar-nav .nav-item .nav-link:hover {
  color: #fff;
}
.topbar.navbar-dark .navbar-nav .nav-item .nav-link:active {
  color: #fff;
}

.topbar.navbar-light .navbar-nav .nav-item .nav-link {
  color: #d1d3e2;
}
.topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: #b7b9cc;
}
.topbar.navbar-light .navbar-nav .nav-item .nav-link:active {
  color: #858796;
}

.sidebar {
  width: 6.5rem;
  min-height: 100vh;
}
.sidebar .nav-item {
  position: relative;
}
.sidebar .nav-item:last-child {
  margin-bottom: 1rem;
}
.sidebar .nav-item .nav-link {
  text-align: center;
  padding: 0.75rem 1rem;
  width: 6.5rem;
}
.sidebar .nav-item .nav-link span {
  font-size: 0.65rem;
  display: block;
}
.sidebar .nav-item.active .nav-link {
  font-weight: 700;
}
.sidebar .nav-item .collapse {
  position: absolute;
  left: calc(6.5rem + 1.5rem / 2);
  z-index: 1;
  top: 2px;
}
.sidebar .nav-item .collapse .collapse-inner {
  border-radius: 0.35rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
}
.sidebar .nav-item .collapsing {
  display: none;
  transition: none;
}
.sidebar .nav-item .collapse .collapse-inner,
.sidebar .nav-item .collapsing .collapse-inner {
  padding: 0.5rem 0;
  min-width: 10rem;
  font-size: 0.85rem;
  margin: 0 0 1rem 0;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-header,
.sidebar .nav-item .collapsing .collapse-inner .collapse-header {
  margin: 0;
  white-space: nowrap;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 0.65rem;
  color: #b7b9cc;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  display: block;
  color: #3a3b45;
  text-decoration: none;
  border-radius: 0.35rem;
  white-space: nowrap;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
  background-color: #eaecf4;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item:active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item:active {
  background-color: #dddfeb;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item.active,
.sidebar .nav-item .collapsing .collapse-inner .collapse-item.active {
  color: #3490dc;
  font-weight: 700;
}
.sidebar #sidebarToggle {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
}
.sidebar #sidebarToggle::after {
  font-weight: 900;
  content: "\f104";
  font-family: "Font Awesome 5 Free";
  margin-right: 0.1rem;
}
.sidebar #sidebarToggle:hover {
  text-decoration: none;
}
.sidebar #sidebarToggle:focus {
  outline: none;
}
.sidebar.toggled {
  width: 0 !important;
  overflow: hidden;
}
.sidebar.toggled #sidebarToggle::after {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  margin-left: 0.25rem;
}
.sidebar.toggled .sidebar-card {
  display: none;
}
.sidebar .sidebar-brand {
  height: 4.375rem;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 800;
  padding: 1.5rem 1rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  z-index: 1;
}
.sidebar .sidebar-brand .sidebar-brand-icon i {
  font-size: 2rem;
}
.sidebar .sidebar-brand .sidebar-brand-text {
  display: none;
}
.sidebar hr.sidebar-divider {
  margin: 0 1rem 1rem;
}
.sidebar .sidebar-heading {
  text-align: center;
  padding: 0 1rem;
  font-weight: 800;
  font-size: 0.65rem;
}
.sidebar .sidebar-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.7875rem;
  border-radius: 0.35rem;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar .sidebar-card .sidebar-card-illustration {
  height: 3rem;
  display: block;
}
.sidebar .sidebar-card .sidebar-card-title {
  font-weight: bold;
}
.sidebar .sidebar-card p {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 768px) {
  .sidebar {
    width: 14rem !important;
  }
  .sidebar .nav-item .collapse {
    position: relative;
    left: 0;
    z-index: 1;
    top: 0;
    animation: none;
  }
  .sidebar .nav-item .collapse .collapse-inner {
    border-radius: 0;
    box-shadow: none;
  }
  .sidebar .nav-item .collapsing {
    display: block;
    transition: height 0.15s ease;
  }
  .sidebar .nav-item .collapse,
  .sidebar .nav-item .collapsing {
    margin: 0 1rem;
  }
  .sidebar .nav-item .nav-link {
    display: block;
    width: 100%;
    text-align: left;
    padding: 1rem;
    width: 14rem;
  }
  .sidebar .nav-item .nav-link i {
    font-size: 0.85rem;
    margin-right: 0.25rem;
  }
  .sidebar .nav-item .nav-link span {
    font-size: 0.85rem;
    display: inline;
  }
  .sidebar .nav-item .nav-link[data-toggle=collapse]::after {
    width: 1rem;
    text-align: center;
    float: right;
    vertical-align: 0;
    border: 0;
    font-weight: 900;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
  }
  .sidebar .nav-item .nav-link[data-toggle=collapse].collapsed::after {
    content: "\f105";
  }
  .sidebar .sidebar-brand .sidebar-brand-icon i {
    font-size: 2rem;
  }
  .sidebar .sidebar-brand .sidebar-brand-text {
    display: inline;
  }
  .sidebar .sidebar-heading {
    text-align: left;
  }
  .sidebar.toggled {
    overflow: visible;
    width: 6.5rem !important;
  }
  .sidebar.toggled .nav-item .collapse {
    position: absolute;
    left: calc(6.5rem + 1.5rem / 2);
    z-index: 1;
    top: 2px;
    animation-name: growIn;
    animation-duration: 200ms;
    animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
  }
  .sidebar.toggled .nav-item .collapse .collapse-inner {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    border-radius: 0.35rem;
  }
  .sidebar.toggled .nav-item .collapsing {
    display: none;
    transition: none;
  }
  .sidebar.toggled .nav-item .collapse,
  .sidebar.toggled .nav-item .collapsing {
    margin: 0;
  }
  .sidebar.toggled .nav-item:last-child {
    margin-bottom: 1rem;
  }
  .sidebar.toggled .nav-item .nav-link {
    text-align: center;
    padding: 0.75rem 1rem;
    width: 6.5rem;
  }
  .sidebar.toggled .nav-item .nav-link span {
    font-size: 0.65rem;
    display: block;
  }
  .sidebar.toggled .nav-item .nav-link i {
    margin-right: 0;
  }
  .sidebar.toggled .nav-item .nav-link[data-toggle=collapse]::after {
    display: none;
  }
  .sidebar.toggled .sidebar-brand .sidebar-brand-icon i {
    font-size: 2rem;
  }
  .sidebar.toggled .sidebar-brand .sidebar-brand-text {
    display: none;
  }
  .sidebar.toggled .sidebar-heading {
    text-align: center;
  }
}
.sidebar-light .sidebar-brand {
  color: #6e707e;
}
.sidebar-light hr.sidebar-divider {
  border-top: 1px solid #eaecf4;
}
.sidebar-light .sidebar-heading {
  color: #b7b9cc;
}
.sidebar-light .nav-item .nav-link {
  color: #858796;
}
.sidebar-light .nav-item .nav-link i {
  color: #d1d3e2;
}
.sidebar-light .nav-item .nav-link:active, .sidebar-light .nav-item .nav-link:focus, .sidebar-light .nav-item .nav-link:hover {
  color: #6e707e;
}
.sidebar-light .nav-item .nav-link:active i, .sidebar-light .nav-item .nav-link:focus i, .sidebar-light .nav-item .nav-link:hover i {
  color: #6e707e;
}
.sidebar-light .nav-item .nav-link[data-toggle=collapse]::after {
  color: #b7b9cc;
}
.sidebar-light .nav-item.active .nav-link {
  color: #6e707e;
}
.sidebar-light .nav-item.active .nav-link i {
  color: #6e707e;
}
.sidebar-light #sidebarToggle {
  background-color: #eaecf4;
}
.sidebar-light #sidebarToggle::after {
  color: #b7b9cc;
}
.sidebar-light #sidebarToggle:hover {
  background-color: #dddfeb;
}

.sidebar-dark .sidebar-brand {
  color: #fff;
}
.sidebar-dark hr.sidebar-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.sidebar-dark .sidebar-heading {
  color: rgba(255, 255, 255, 0.4);
}
.sidebar-dark .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.8);
}
.sidebar-dark .nav-item .nav-link i {
  color: rgba(255, 255, 255, 0.3);
}
.sidebar-dark .nav-item .nav-link:active, .sidebar-dark .nav-item .nav-link:focus, .sidebar-dark .nav-item .nav-link:hover {
  color: #fff;
}
.sidebar-dark .nav-item .nav-link:active i, .sidebar-dark .nav-item .nav-link:focus i, .sidebar-dark .nav-item .nav-link:hover i {
  color: #fff;
}
.sidebar-dark .nav-item .nav-link[data-toggle=collapse]::after {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark .nav-item.active .nav-link {
  color: #fff;
}
.sidebar-dark .nav-item.active .nav-link i {
  color: #fff;
}
.sidebar-dark #sidebarToggle {
  background-color: rgba(255, 255, 255, 0.2);
}
.sidebar-dark #sidebarToggle::after {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark #sidebarToggle:hover {
  background-color: rgba(255, 255, 255, 0.25);
}
.sidebar-dark.toggled #sidebarToggle::after {
  color: rgba(255, 255, 255, 0.5);
}

.btn-circle {
  border-radius: 100%;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-circle.btn-sm, .btn-group-sm > .btn-circle.btn {
  height: 1.8rem;
  width: 1.8rem;
  font-size: 0.75rem;
}
.btn-circle.btn-lg, .btn-group-lg > .btn-circle.btn {
  height: 3.5rem;
  width: 3.5rem;
  font-size: 1.35rem;
}

.btn-icon-split {
  padding: 0;
  overflow: hidden;
  display: inline-flex;
  align-items: stretch;
  justify-content: center;
}
.btn-icon-split .icon {
  background: rgba(0, 0, 0, 0.15);
  display: inline-block;
  padding: 0.375rem 0.75rem;
}
.btn-icon-split .text {
  display: inline-block;
  padding: 0.375rem 0.75rem;
}
.btn-icon-split.btn-sm .icon, .btn-group-sm > .btn-icon-split.btn .icon {
  padding: 0.25rem 0.5rem;
}
.btn-icon-split.btn-sm .text, .btn-group-sm > .btn-icon-split.btn .text {
  padding: 0.25rem 0.5rem;
}
.btn-icon-split.btn-lg .icon, .btn-group-lg > .btn-icon-split.btn .icon {
  padding: 0.5rem 1rem;
}
.btn-icon-split.btn-lg .text, .btn-group-lg > .btn-icon-split.btn .text {
  padding: 0.5rem 1rem;
}

.card .card-header .dropdown {
  line-height: 1;
}
.card .card-header .dropdown .dropdown-menu {
  line-height: 1.5;
}
.card .card-header[data-toggle=collapse] {
  text-decoration: none;
  position: relative;
  padding: 0.75rem 3.25rem 0.75rem 1.25rem;
}
.card .card-header[data-toggle=collapse]::after {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 1.725rem;
  line-height: 51px;
  font-weight: 900;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  color: #d1d3e2;
}
.card .card-header[data-toggle=collapse].collapsed {
  border-radius: 0.35rem;
}
.card .card-header[data-toggle=collapse].collapsed::after {
  content: "\f105";
}

.chart-area {
  position: relative;
  height: 10rem;
  width: 100%;
}
@media (min-width: 768px) {
  .chart-area {
    height: 20rem;
  }
}

.chart-bar {
  position: relative;
  height: 10rem;
  width: 100%;
}
@media (min-width: 768px) {
  .chart-bar {
    height: 20rem;
  }
}

.chart-pie {
  position: relative;
  height: 15rem;
  width: 100%;
}
@media (min-width: 768px) {
  .chart-pie {
    height: calc(20rem - 43px) !important;
  }
}

.bg-login-image {
  background: url("https://source.unsplash.com/K4mSJ7kc0As/600x800");
  background-position: center;
  background-size: cover;
}

.bg-register-image {
  background: url("https://source.unsplash.com/Mv9hjnEUHR4/600x800");
  background-position: center;
  background-size: cover;
}

.bg-password-image {
  background: url("https://source.unsplash.com/oWTW-jNGl9I/600x800");
  background-position: center;
  background-size: cover;
}

form.user .custom-checkbox.small label {
  line-height: 1.5rem;
}
form.user .form-control-user {
  font-size: 0.8rem;
  border-radius: 10rem;
  padding: 1.5rem 1rem;
}
form.user .btn-user {
  font-size: 0.8rem;
  border-radius: 10rem;
  padding: 0.75rem 1rem;
}

.btn-google {
  color: #fff;
  background-color: #ea4335;
  border-color: #fff;
}
.btn-google:hover {
  color: #fff;
  background-color: #e12717;
  border-color: #e6e6e6;
}
.btn-google:focus, .btn-google.focus {
  color: #fff;
  background-color: #e12717;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-google.disabled, .btn-google:disabled {
  color: #fff;
  background-color: #ea4335;
  border-color: #fff;
}
.btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active, .show > .btn-google.dropdown-toggle {
  color: #fff;
  background-color: #d62516;
  border-color: #dfdfdf;
}
.btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus, .show > .btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #fff;
}
.btn-facebook:hover {
  color: #fff;
  background-color: #30497c;
  border-color: #e6e6e6;
}
.btn-facebook:focus, .btn-facebook.focus {
  color: #fff;
  background-color: #30497c;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-facebook.disabled, .btn-facebook:disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: #fff;
}
.btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active, .show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2d4373;
  border-color: #dfdfdf;
}
.btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus, .show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.error {
  color: #5a5c69;
  font-size: 7rem;
  position: relative;
  line-height: 1;
  width: 12.5rem;
}

@keyframes noise-anim {
  0% {
    clip: rect(78px, 9999px, 10px, 0);
  }
  5% {
    clip: rect(16px, 9999px, 85px, 0);
  }
  10% {
    clip: rect(15px, 9999px, 56px, 0);
  }
  15% {
    clip: rect(61px, 9999px, 26px, 0);
  }
  20% {
    clip: rect(8px, 9999px, 69px, 0);
  }
  25% {
    clip: rect(66px, 9999px, 53px, 0);
  }
  30% {
    clip: rect(13px, 9999px, 23px, 0);
  }
  35% {
    clip: rect(41px, 9999px, 29px, 0);
  }
  40% {
    clip: rect(37px, 9999px, 38px, 0);
  }
  45% {
    clip: rect(71px, 9999px, 34px, 0);
  }
  50% {
    clip: rect(89px, 9999px, 60px, 0);
  }
  55% {
    clip: rect(14px, 9999px, 20px, 0);
  }
  60% {
    clip: rect(78px, 9999px, 64px, 0);
  }
  65% {
    clip: rect(20px, 9999px, 12px, 0);
  }
  70% {
    clip: rect(16px, 9999px, 55px, 0);
  }
  75% {
    clip: rect(30px, 9999px, 59px, 0);
  }
  80% {
    clip: rect(60px, 9999px, 35px, 0);
  }
  85% {
    clip: rect(42px, 9999px, 13px, 0);
  }
  90% {
    clip: rect(17px, 9999px, 73px, 0);
  }
  95% {
    clip: rect(19px, 9999px, 32px, 0);
  }
  100% {
    clip: rect(38px, 9999px, 17px, 0);
  }
}
.error:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 #e3342f;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

@keyframes noise-anim-2 {
  0% {
    clip: rect(91px, 9999px, 38px, 0);
  }
  5% {
    clip: rect(98px, 9999px, 64px, 0);
  }
  10% {
    clip: rect(93px, 9999px, 32px, 0);
  }
  15% {
    clip: rect(52px, 9999px, 26px, 0);
  }
  20% {
    clip: rect(87px, 9999px, 23px, 0);
  }
  25% {
    clip: rect(11px, 9999px, 68px, 0);
  }
  30% {
    clip: rect(53px, 9999px, 5px, 0);
  }
  35% {
    clip: rect(96px, 9999px, 7px, 0);
  }
  40% {
    clip: rect(77px, 9999px, 40px, 0);
  }
  45% {
    clip: rect(95px, 9999px, 58px, 0);
  }
  50% {
    clip: rect(82px, 9999px, 45px, 0);
  }
  55% {
    clip: rect(71px, 9999px, 77px, 0);
  }
  60% {
    clip: rect(41px, 9999px, 100px, 0);
  }
  65% {
    clip: rect(2px, 9999px, 93px, 0);
  }
  70% {
    clip: rect(88px, 9999px, 52px, 0);
  }
  75% {
    clip: rect(58px, 9999px, 29px, 0);
  }
  80% {
    clip: rect(92px, 9999px, 83px, 0);
  }
  85% {
    clip: rect(82px, 9999px, 76px, 0);
  }
  90% {
    clip: rect(90px, 9999px, 62px, 0);
  }
  95% {
    clip: rect(71px, 9999px, 61px, 0);
  }
  100% {
    clip: rect(57px, 9999px, 16px, 0);
  }
}
.error:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 #3490dc;
  top: 0;
  color: #5a5c69;
  background: #f8f9fc;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}

footer.sticky-footer {
  padding: 2rem 0;
  flex-shrink: 0;
}
footer.sticky-footer .copyright {
  line-height: 1;
  font-size: 0.8rem;
}

body.sidebar-toggled footer.sticky-footer {
  width: 100%;
}

.btn-circle.btn-lg, .btn-group-lg > .btn-circle.btn {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1rem;
}

.sidebar .nav-item .nav-link {
  padding: 0.5rem 1rem !important;
}

form.user .form-control-user,
form.user .btn-user {
  border-radius: 0 !important;
}

form.user .form-control-user {
  padding: 0.375rem 0.75rem;
}

.draggable-mirror {
  background: rgba(204, 204, 204, 0.62) !important;
  width: auto !important;
  cursor: all-scroll !important;
}

@font-face {
  font-family: "obvia";
  src: url("https://use.typekit.net/af/280725/00000000000000007735bd10/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/280725/00000000000000007735bd10/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/280725/00000000000000007735bd10/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n3&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}
@font-face {
  font-family: "obvia";
  src: url("https://use.typekit.net/af/7318a4/00000000000000007735bd1f/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/7318a4/00000000000000007735bd1f/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/7318a4/00000000000000007735bd1f/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}
@font-face {
  font-family: "obvia";
  src: url("https://use.typekit.net/af/a9b63c/00000000000000007735bd20/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3") format("woff2"), url("https://use.typekit.net/af/a9b63c/00000000000000007735bd20/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3") format("woff"), url("https://use.typekit.net/af/a9b63c/00000000000000007735bd20/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}
@font-face {
  font-family: "hws-font";
  src: url("fonts/hsw/font.woff") format("woff2"), url("fonts/hsw/font.woff") format("woff");
}
.btn-box {
  text-align: center;
}
.btn-box .btn-box-content {
  display: inline-block;
  position: relative;
}
.btn-box .btn-box-content img {
  position: absolute;
  left: -10px;
  top: 50%;
  margin-top: -9px;
  height: 18px;
  width: auto;
  transform: translateX(-100%);
}

.btn {
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  height: 40px;
  padding: 9px 38px;
  width: 300px;
  max-width: 95%;
  border-radius: 20px;
  text-decoration: none;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}
@media (max-width: 768px) {
  .btn {
    margin-bottom: 20px;
  }
}
.btn.btn-small {
  width: 230px;
}
.btn img {
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -9px;
  height: 18px;
  width: auto;
}
.btn.btn-light {
  color: #484D48;
  background: #FFFFFF;
  border: 1px solid transparent;
}
.btn.btn-light:hover {
  background: #E9EDEF;
  border: 1px solid transparent;
}
.btn.btn-light:hover .btn-box-content,
.btn.btn-light:hover span {
  color: #484D48;
}
.btn.btn-success {
  color: #484D48;
  background: #02FD68;
  border: #02FD68;
}
.btn.btn-success:hover {
  background: #26E86C;
  border: #26E86C;
}
.btn.btn-success:hover .btn-box-content,
.btn.btn-success:hover span {
  color: #484D48;
}
.btn.btn-primary {
  color: #484D48;
  background: #C8E5EE;
  border: 1px solid transparent;
}
.btn.btn-primary:hover {
  background: #ADD9E6;
  border: 1px solid transparent;
}
.btn.btn-primary:hover .btn-box-content,
.btn.btn-primary:hover span {
  color: #484D48;
}
.btn.cc-primary {
  color: #252928;
  background: #2BFD68;
  border: #2BFD68;
}
.btn.cc-primary:hover {
  background: #26E86C;
  border: #25E46A;
}
.btn.cc-primary:hover .btn-box-content,
.btn.cc-primary:hover span {
  color: #252928;
}
.btn.cc-secondary {
  color: #252928;
  background: #C9E6EF;
  border: #C9E6EF;
}
.btn.cc-secondary:hover {
  background: #92CCDE;
  border: #F7F7F7;
}
.btn.cc-secondary:hover .btn-box-content,
.btn.cc-secondary:hover span {
  color: #252928;
}
.btn.hsw-primary {
  color: #252928;
  background: #2BFD68;
  border: #2BFD68;
}
.btn.hsw-primary:hover {
  background: #26E86C;
  border: #25E46A;
}
.btn.hsw-primary:hover .btn-box-content,
.btn.hsw-primary:hover span {
  color: #252928;
}
.btn.hsw-secondary {
  color: #252928;
  background: #EBEBEB;
  border: #252928;
}
.btn.hsw-secondary:hover {
  background: #FFFFFF;
  border: #252928;
}
.btn.hsw-secondary:hover .btn-box-content,
.btn.hsw-secondary:hover span {
  color: #252928;
}
.btn.btn-dark {
  color: #FFFFFF;
  background: #484d48;
  border: 1px solid transparent;
}
.btn.btn-dark:hover {
  background: #252928;
  border: 1px solid transparent;
  color: #FFFFFF;
}
.btn.btn-dark:hover .btn-box-content,
.btn.btn-dark:hover span {
  color: #FFFFFF;
}
.btn.btn-dark .btn-text {
  color: #FFFFFF !important;
}
.btn.btn-dark .btn-text:hover {
  color: #FFFFFF !important;
}
.btn.disabled {
  pointer-events: none;
}
.btn.disabled img,
.btn.disabled span {
  opacity: 0.45;
}

form textarea {
  border: 1px solid #D5D5D5;
}
form .form-group label,
form .form-group .form-check-label {
  font-size: 16px;
  font-weight: 600;
}
form .form-group .check-label-box {
  font-weight: 600;
}
form .form-group .form-label {
  font-size: 12px;
  color: #484D48;
  margin-bottom: 0.25rem;
  font-weight: 600;
}
form .form-group .form-label.big-label {
  font-size: 20px;
}
form .form-group .form-control {
  min-height: 40px;
  border: 1px solid #D5D5D5;
  background-color: #F6F6F4;
  border-radius: 0;
}
form .form-group .form-control:focus {
  border-color: #858585;
  box-shadow: none;
}
form .form-group .form-control::placeholder {
  color: #C1C1C1;
  opacity: 1;
}
form .form-group .form-control:-ms-input-placeholder {
  color: #C1C1C1;
}
form .form-group .form-control::-ms-input-placeholder {
  color: #C1C1C1;
}

.form-check-box {
  position: relative;
  cursor: pointer;
  width: 100%;
}
.form-check-box input {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
}
.form-check-box .check-label {
  display: flex;
  margin-bottom: 0;
}
.form-check-box .check-label .check-label-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 2px solid #242a28;
  background-color: #FFFFFF;
  margin-right: 0.5rem;
  cursor: pointer;
}
.form-check-box input:checked + .check-label .check-label-box {
  border: 1px solid #2BFD68;
  background-color: #2BFD68;
}
.form-check-box input:checked + .check-label .check-label-box i {
  margin-top: -2px;
  background: #242a28;
}

.form-check-hsw {
  position: relative;
  cursor: pointer;
  width: 100%;
  padding-left: 0;
}
.form-check-hsw .form-check-input {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
}
.form-check-hsw .form-check-label {
  display: flex;
  margin-bottom: 0;
  font-weight: 600;
}
.form-check-hsw .form-check-label:hover {
  color: initial;
}
.form-check-hsw .form-check-label .check-label-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 2px solid #242a28;
  background-color: #FFFFFF;
  margin-right: 0.5rem;
  cursor: pointer;
}
.form-check-hsw .form-check-input:checked + .form-check-label .check-label-box {
  border: 1px solid #2BFD68;
  background-color: #2BFD68;
}
.form-check-hsw .form-check-input:checked + .form-check-label .check-label-box i {
  margin-top: -2px;
  background: #242a28;
}

.form-check-hsw-sub {
  position: relative;
  cursor: pointer;
  padding-left: 0;
}
.form-check-hsw-sub .form-check-input {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
}
.form-check-hsw-sub .form-check-label {
  display: flex;
  margin-bottom: 0;
}
.form-check-hsw-sub .form-check-label:hover {
  color: initial;
}
.form-check-hsw-sub .form-check-label .check-label-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid #D5D5D5;
  background-color: #f6f6f4;
  color: #f6f6f4;
  font-size: 1.125rem;
  margin-right: 0.5rem;
  cursor: pointer;
}
.form-check-hsw-sub .form-check-input:checked + .form-check-label .check-label-box {
  border: 1px solid #2BFD68;
  background-color: #2BFD68;
}
.form-check-hsw-sub .form-check-input:checked + .form-check-label .check-label-box i {
  margin-top: -2px;
  background: #242a28;
}

.filter-brand-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.25rem;
}
.filter-brand-group .filter-brand {
  position: relative;
  width: 33.33%;
  height: 50px;
  box-sizing: border-box;
  padding: 0.25rem;
}
.filter-brand-group .filter-brand .logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.25s ease;
  background-color: #E0E0E0;
}
.filter-brand-group .filter-brand input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.filter-brand-group .filter-brand input[type=checkbox]:checked ~ .logo {
  background-color: #CAE2F0;
}

.form-select-hswm {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  border: 1px solid #D5D5D5;
  background-color: #F6F6F4;
  width: 100%;
  position: relative;
  height: 90px;
}
.form-select-hswm select {
  width: 100%;
  padding: 10px;
  position: absolute;
  top: -10px;
  left: -10px;
  bottom: -10px;
  width: calc(100% + 10px);
  background: transparent;
}

.form-select-hsw {
  position: relative;
  height: 40px;
  background-color: #F6F6F4;
}
.form-select-hsw .form-control {
  position: absolute;
  background-color: transparent !important;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 0 45px 0 10px;
}
.form-select-hsw:before {
  content: "";
  position: absolute;
  border-left: 1px solid #D5D5D5;
  width: 40px;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 0;
}
.form-select-hsw:after {
  content: "";
  position: absolute;
  right: 13.5px;
  top: 15.5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 6.5px 0 6.5px;
  border-color: #343a3f transparent transparent transparent;
  z-index: 0;
}
.form-select-hsw .is-invalid {
  background-position: right 3rem center !important;
}
.form-select-hsw .invalid-feedback {
  margin-top: 0;
  padding-top: 44px;
}
.form-select-hsw.form-date:after {
  content: "\f1f6";
  font-family: bootstrap-icons;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-upload {
  position: relative;
}
.form-upload label,
.form-upload .form-label {
  background-color: #F5F5F5;
  border: 1px solid #D5D5D5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  margin-bottom: 0;
  font-weight: bold;
  cursor: pointer;
}
.form-upload label i,
.form-upload .form-label i {
  font-size: 1.5rem;
}
.form-upload.form-upload-button .form-label {
  background-color: #C9E6EF;
  padding: 1rem;
  border: none;
}
.form-upload input[type=file] {
  font-size: 80%;
}
.form-upload input[type=file]::-ms-browse {
  display: none;
}
.form-upload input[type=file]::-webkit-file-upload-button {
  display: none;
}

.dropdown-menu.show {
  transform: unset !important;
}

.custom-dropdown .custom-dropdown-btn {
  background-color: #F5F5F5;
  position: relative;
  height: 40px;
}
.custom-dropdown .custom-dropdown-btn .dropdown-toggle {
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.custom-dropdown .custom-dropdown-btn .dropdown-toggle.is-invalid {
  background-position: right 3rem center !important;
}
.custom-dropdown .custom-dropdown-btn .btn-appearance {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border-left: 1px solid #D5D5D5;
}
.custom-dropdown .custom-dropdown-btn .btn-appearance:after {
  content: "";
  position: absolute;
  right: 13.5px;
  top: 15.5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 6.5px 0 6.5px;
  border-color: #343a3f transparent transparent transparent;
}
.custom-dropdown .custom-dropdown-btn .dropdown-toggle:disabled,
.custom-dropdown .custom-dropdown-btn .dropdown-toggle.disabled-drop-down {
  color: #E0E0E0;
  cursor: default;
}
.custom-dropdown .custom-dropdown-btn .dropdown-toggle:disabled + .btn-appearance:after,
.custom-dropdown .custom-dropdown-btn .dropdown-toggle.disabled-drop-down + .btn-appearance:after {
  border-color: #E0E0E0 transparent transparent transparent;
}
.custom-dropdown.show > .dropdown-toggle {
  background-color: #FFFFFF;
}
.custom-dropdown > .dropdown-toggle:focus {
  box-shadow: none;
}
.custom-dropdown > .dropdown-menu {
  width: 100%;
  border: 1px solid #D5D5D5;
  margin-top: -3px;
  top: 0;
  padding: 0;
}
.custom-dropdown > .dropdown-menu .inner-button i {
  font-size: 1.5rem;
  line-height: 1;
}
.custom-dropdown > .dropdown-menu .inner {
  padding: 1rem;
}
.custom-dropdown > .dropdown-menu .dropdown-toggle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 1rem;
  position: relative;
}
.custom-dropdown > .dropdown-menu .dropdown-item {
  padding: 0;
  display: flex;
  margin: 0.5rem 0;
}
.custom-dropdown > .dropdown-menu .dropdown-item .icon {
  min-width: 3rem;
  height: 3rem;
  background-color: #F6F6F4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 0.5rem solid #FFFFFF;
  box-sizing: content-box;
}
.custom-dropdown > .dropdown-menu .dropdown-item .icon i {
  font-size: 1.5rem;
}
.custom-dropdown > .dropdown-menu .dropdown-item .name {
  border-bottom: 1px solid #D5D5D5;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}
.custom-dropdown .dropdown-menu.show {
  transform: unset !important;
}

@media (max-width: 767.98px) {
  body.custom-dropdown-open {
    height: 100vh;
    overflow-y: hidden;
  }
  body.custom-dropdown-open header {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .custom-dropdown {
    position: unset !important;
  }
  .custom-dropdown > .dropdown-menu.show {
    position: fixed !important;
    padding: 0;
    width: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    border: 0;
  }
  .custom-dropdown > .dropdown-menu.show .inner-header {
    height: 43px;
    color: #FFFFFF;
    text-align: center;
    font-size: 1.75rem;
    align-content: center;
    justify-content: center;
    line-height: 1.7;
  }
  .custom-dropdown > .dropdown-menu.show .inner-button i {
    font-size: 2.25rem;
  }
  .custom-dropdown > .dropdown-menu.show .inner {
    height: calc(100vh - 43px);
    overflow: scroll;
    padding: 1rem;
  }
}
@media (min-width: 576px) {
  .custom-dropdown {
    /* Scroll bar */
  }
  .custom-dropdown .dropdown-menu .inner {
    height: 300px;
    overflow-y: scroll;
    padding-right: 0;
  }
  .custom-dropdown .dropdown-menu .inner::-webkit-scrollbar {
    width: 1rem;
  }
  .custom-dropdown .dropdown-menu .inner::-webkit-scrollbar-track {
    background: #F5F5F5;
  }
  .custom-dropdown .dropdown-menu .inner::-webkit-scrollbar-thumb {
    background: #D5D5D5;
  }
  .custom-dropdown .dropdown-menu .inner::-webkit-scrollbar-thumb:hover {
    background: #BFBFBF;
  }
}
.hsw-link {
  color: #000000;
  text-decoration: underline;
  text-underline: #000000 !important;
}
.hsw-link:hover {
  color: #26e86c;
  text-underline: #26e86c;
}

.cc-link {
  color: #58a5be;
}
.cc-link [class^=icon-] {
  background-color: #58a5be;
}
.cc-link:hover {
  color: #92ccde;
  text-underline: #92ccde;
  cursor: pointer;
}
.cc-link:hover [class^=icon-] {
  background-color: #92ccde;
}

.btn-link.btn-edit {
  font-weight: 600;
  color: #58a5be;
  cursor: pointer;
  text-decoration: none;
}
.btn-link.btn-edit img {
  filter: invert(0.6) sepia(1) saturate(100) hue-rotate(190deg) opacity(0.6);
}
.btn-link.btn-edit:hover {
  color: #92ccde;
}
.btn-link.btn-edit:hover img {
  filter: invert(0.6) sepia(1) saturate(100) hue-rotate(190deg) opacity(0.4);
}

.hidden {
  display: none !important;
}

.checkers-wrapper {
  background: #F6F6F4;
  padding: 8px 15px;
  height: 40px;
}
.checkers-wrapper .off-stage {
  position: absolute;
  left: -5000px;
}
.checkers-wrapper .checker {
  cursor: pointer;
  margin-bottom: 5px;
  align-items: center;
  width: auto;
  float: left;
  margin-right: 15px;
}
.checkers-wrapper input:checked + .check-label-box {
  border: 1px solid #2BFD68;
  background-color: #2BFD68;
}
.checkers-wrapper input:checked + .check-label-box i {
  margin-top: -2px;
  background: #242a28;
}

input:checked + .form-check-label .check-label-box {
  border: 1px solid #2BFD68;
  background-color: #2BFD68;
}
input:checked + .form-check-label .check-label-box i {
  margin-top: -2px;
  background: #242a28;
}

/* Border */
.hr-pyr {
  border-bottom: 1px solid #E5E5E5;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.hr-pyr:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -10px;
  width: 20px;
  height: 20px;
  left: 48%;
  background: #FFFFFF;
  border-right: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  transform: rotate(224deg);
}

.guarantee-detail-card {
  padding: 1rem 0;
}

.customer-template-card {
  background: #f6f6f4;
  padding: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
}
.customer-template-card:nth-child(even) {
  background: #FFFFFF;
}

.product-catalog-card {
  border-top: 1px solid #E0E0E0;
}
.product-catalog-card:first-child {
  border-top: 0 none;
}
.product-catalog-card:last-child {
  border-bottom: 1px solid #E0E0E0;
}
.product-catalog-card .card-inner-link {
  display: block;
  padding: 1rem 0;
}
.product-catalog-card .card-inner-link:hover {
  color: #58A5BE;
}

.guarantee-bookings-card {
  position: relative;
  padding: 2rem 5rem 1rem 5rem;
}
.guarantee-bookings-card .collapse-box {
  background-color: #F5F5F5;
  padding: 1rem;
  font-size: 0.875rem;
  position: relative;
}
.guarantee-bookings-card .collapse-box .close-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.guarantee-bookings-card .card-column {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}
.guarantee-bookings-card .card-column .card-head {
  font-size: 12px;
  font-weight: 600;
}
.guarantee-bookings-card .card-column .label {
  min-width: 3.5rem;
  min-height: 3.5rem;
  border-radius: 50%;
  background-color: #CAE2F0;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 80%;
  line-height: 1.125;
}
.guarantee-bookings-card .card-column .label.label-accent-color {
  background-color: #ff4b67;
}
.guarantee-bookings-card .card-column .next {
  font-size: 2rem;
  color: #E0E0E0;
  transition: color 0.3s;
}
.guarantee-bookings-card .card-column a {
  color: #484D48;
}
.guarantee-bookings-card .card-column a:hover {
  text-decoration: none;
  color: #92CCDE;
}
.guarantee-bookings-card .card-column a:hover .svg-icon,
.guarantee-bookings-card .card-column a:hover .svg-icon #Ellipse_130,
.guarantee-bookings-card .card-column a:hover .svg-icon #Pfad_1072,
.guarantee-bookings-card .card-column a:hover .svg-icon #Pfad_438 {
  fill: #92CCDE;
}
.guarantee-bookings-card .card-column a .svg-icon,
.guarantee-bookings-card .card-column a .svg-icon #Ellipse_130,
.guarantee-bookings-card .card-column a .svg-icon #Pfad_1072,
.guarantee-bookings-card .card-column a .svg-icon #Pfad_438 {
  fill: #484D48;
}
.guarantee-bookings-card .card-column .icon-wrap {
  width: 70px;
  text-align: center;
  margin-left: 1.5rem;
  font-size: 12px;
  font-weight: 400;
}
.guarantee-bookings-card .card-column .icon-wrap .icon {
  width: 36px;
}
@media (max-width: 991px) {
  .guarantee-bookings-card .card-column .icon-wrap {
    margin-left: 0;
  }
}
.guarantee-bookings-card .box-icon {
  width: 28px;
  margin-bottom: 0.5rem;
  max-width: 90%;
}
.guarantee-bookings-card > a:first-child {
  color: inherit;
  display: block;
  padding: 1rem;
  margin: 0 -1rem;
  transition: background-color 0.3s ease-in-out;
}
.guarantee-bookings-card > a:first-child:hover {
  text-decoration: none;
  background-color: #F5F5F5;
}
.guarantee-bookings-card > a:first-child:hover .next {
  color: #242a28;
}
.guarantee-bookings-card .small {
  font-size: 14px;
}
.guarantee-bookings-card .small.link {
  font-weight: 600;
}

.guarantee-card-component-wrap {
  background-color: #C9E6EF;
  padding: 2rem 3rem;
  font-size: 12px;
  position: relative;
}
.guarantee-card-component-wrap .card-head {
  font-weight: 600;
}
.guarantee-card-component-wrap .close-icon {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  cursor: pointer;
}

.bt-1 {
  border-top: 1px solid;
  padding-top: 15px;
  font-weight: bold;
}

.alert-hsw {
  background: #99a594;
  color: #FFFFFF;
  border-radius: 0;
  position: relative;
}
.alert-hsw::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-top: 1rem solid #99a594;
  right: 1.5rem;
  bottom: -1rem;
}

.notify-modal-open .notify-modal .modal-header {
  background-color: #FFFFFF;
}
.notify-modal-open .notify-modal .modal-body {
  background-color: #FFFFFF;
}
.notify-modal-open .notify-modal .modal-body .label {
  position: absolute;
  top: 0;
  right: 0;
  width: 5rem;
  padding: 1rem;
}

.modal-content {
  border-radius: 0 !important;
  border: none !important;
}
.modal-content .title {
  font-size: 24px;
  font-weight: 600;
}

.modal-bg-dark {
  background: rgba(0, 0, 0, 0.5) !important;
}
.modal-bg-dark .fade {
  background: rgba(0, 0, 0, 0.5) !important;
}

.gc-overlay {
  background: #ffffff;
}

@media (max-width: 767.98px) {
  .responsive-modal .modal-dialog {
    margin: 0;
    min-width: 100%;
  }
  .responsive-modal .modal-dialog .modal-content {
    border: none;
    height: 100%;
  }
  .responsive-modal-catalog .modal-dialog {
    margin: 0;
    min-width: 100%;
  }
  .responsive-modal-catalog .modal-dialog .modal-content {
    top: 40px;
    border: none;
    height: calc(100% - 40px);
  }
  .responsive-modal-catalog ~ .modal-backdrop {
    top: 40px;
  }
}
@media (min-width: 576px) {
  .notify-modal-open {
    overflow: inherit !important;
    padding-right: 0 !important;
  }
  .notify-modal-open .notify-modal {
    position: absolute;
    width: auto;
    height: auto;
    top: 2rem;
    right: 5rem;
    left: auto;
  }
  .notify-modal-open .notify-modal .modal-dialog {
    position: unset;
    width: 500px;
    z-index: 9999;
    right: 0;
    margin: 2rem;
  }
  .notify-modal-open .modal-backdrop {
    display: none;
  }
}
.modal-logo {
  max-width: 100%;
  height: auto;
  width: 100px;
}
@media (min-width: 992px) {
  .modal-logo {
    width: 175px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 1000px;
  }
}
.lh-0 {
  line-height: 1;
}

.lh-1 {
  line-height: 1.25rem;
}

.lh-2 {
  line-height: 1.5rem;
}

.lh-3 {
  line-height: 1.75rem;
}

.lh-4 {
  line-height: 2rem;
}

.text-primary {
  color: #58A5BE !important;
}

.indicators {
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}
.indicators li a {
  padding: 1rem;
}
.indicators li a:hover, .indicators li a.active {
  color: #E0E0E0;
}

.bg-gray {
  background-color: #F5F5F5 !important;
}

.bg-green {
  background-color: #eef4ec !important;
}

.bg-welldone {
  background-color: #D6FDE4 !important;
}

.bg-attention {
  background-color: #FFFED9 !important;
}

.bg-warning {
  background-color: #FCE4BE !important;
}

.bg-danger {
  background-color: #F8C2C2 !important;
}

@media (min-width: 992px) {
  .bg-lg-white {
    background-color: white !important;
  }
}
.accordion .accordion-item {
  background-color: #F5F5F5;
  padding: 2rem;
}
.accordion .accordion-item:not(:last-child) {
  margin-bottom: 1rem;
}
.accordion .accordion-item .header {
  display: flex;
}
.accordion .accordion-item .header .collapse-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  color: #242a28;
  margin-left: auto;
}
.accordion .accordion-item .header .collapse-btn::after {
  font-family: "bootstrap-icons";
  content: "\f64d";
  position: absolute;
  font-size: 1.5rem;
  transition: transform 0.3s ease-in-out;
  -ms-transition: -ms-transform 0.3s ease-in-out;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
}
.accordion .accordion-item .header .collapse-btn[aria-expanded=true]::after {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.logo-rec {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo__gira img {
  width: 70%;
  height: auto;
}
.logo__jung img {
  width: 70%;
  height: auto;
}
.logo__brumberg img {
  width: 90%;
  height: auto;
}

.manufacturer-image {
  margin: 0;
  padding: 0px !important;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
}
@media (min-width: 768px) {
  .manufacturer-image {
    padding: 15px !important;
    flex: 0 0 20%;
  }
}
.manufacturer-image img {
  width: 100%;
  height: auto;
}
@media (max-width: 768px) {
  .manufacturer-image img {
    display: block;
    margin: 0 auto;
    width: 50%;
  }
}

.list-unstyled {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.notification-wrapper {
  border-radius: 0 !important;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  min-height: 300px;
  padding: 2rem;
}
@media (min-width: 680px) {
  .notification-wrapper {
    min-width: 580px;
  }
}
.notification-wrapper .column {
  padding: 2rem;
}
.notification-wrapper .close-icon {
  text-align: right;
  margin-bottom: 1rem;
}
.notification-wrapper .title {
  font-weight: 600;
  margin-bottom: 1rem;
}
.notification-wrapper .description {
  margin-bottom: 2rem;
}

i[class^=icon-] {
  --icon-size: 30px;
  width: var(--icon-size);
  height: var(--icon-size);
  display: inline-block;
}
i.icon-link-invoice {
  -webkit-mask: url("../../../images/icons/link_invoice.svg") no-repeat center;
  mask: url("../../../images/icons/link_invoice.svg") no-repeat center;
}
i.icon-reset {
  -webkit-mask: url("../../../images/icons/reset_outline.svg") no-repeat center;
  mask: url("../../../images/icons/reset_outline.svg") no-repeat center;
}
i.icon-trash {
  -webkit-mask: url("../../../images/icons/trash_outline.svg") no-repeat center;
  mask: url("../../../images/icons/trash_outline.svg") no-repeat center;
}
i.icon-file {
  -webkit-mask: url("../../../images/icons/file_outline.svg") no-repeat center;
  mask: url("../../../images/icons/file_outline.svg") no-repeat center;
}
i.icon-plus {
  -webkit-mask: url("../../../images/icons/plus_square_with_holes_outline.svg") no-repeat center;
  mask: url("../../../images/icons/plus_square_with_holes_outline.svg") no-repeat center;
}
i.icon-check {
  -webkit-mask: url("../../../images/icons/check_outline.svg") no-repeat center;
  mask: url("../../../images/icons/check_outline.svg") no-repeat center;
}
i.icon-shield-document {
  -webkit-mask: url("../../../images/icons/shield-document.svg") no-repeat center;
  mask: url("../../../images/icons/shield-document.svg") no-repeat center;
}
i.icon-user-arrow {
  -webkit-mask: url("../../../images/icons/user-arrow-up.svg") no-repeat center;
  mask: url("../../../images/icons/user-arrow-up.svg") no-repeat center;
}
i.icon-exclamation-triangle {
  -webkit-mask: url("../../../images/icons/exclamation-triangle_outline.svg") no-repeat center;
  mask: url("../../../images/icons/exclamation-triangle_outline.svg") no-repeat center;
}

.cursor-pointer {
  cursor: pointer;
}

.fixed-full-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.fixed-full-container.has-index {
  z-index: -1;
}

.hamburger-hsw {
  display: inline-block;
  height: 70px;
  vertical-align: middle;
}

@media (min-width: 992px) {
  .custom-calc-padding {
    padding-left: calc(5rem + 15px);
  }
}
@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-block {
    display: block !important;
  }
}
@media (max-width: 991px) {
  h1, h2, h3 {
    font-size: 0.9rem !important;
  }
  .heading-container {
    padding: 1rem 2rem !important;
  }
  .heading-container .title {
    font-size: 0.9rem !important;
  }
  .guarantee-bookings-card {
    padding: 1rem 2rem 1rem 2rem !important;
  }
  .guarantee-bookings-card .card-column .icon-wrap {
    font-size: 9px !important;
  }
  .hamburger-hsw {
    display: inline-block;
    height: 40px;
    vertical-align: middle;
  }
}
.btn-close {
  padding-right: 25px;
  cursor: pointer;
}

@media (min-width: 815px) {
  .h-md-100 {
    height: 100% !important;
  }
}
body {
  font-family: "obvia", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  color: #484d48;
  position: relative;
  min-height: 100vh;
  background: #F6F6F4;
  line-height: 1.3;
}
body h1, body h2, body h3, body h4, body h5, body h6 {
  font-weight: 600 !important;
  margin-bottom: 0;
}
body h1 {
  font-size: 1.35rem;
}
body h3 {
  font-size: 1.0125rem;
}
body .font-weight-bold {
  font-weight: 600 !important;
}
body .logo-font {
  font-family: "hws-font";
}
body #app {
  min-height: 100vh;
}
body #app .vh-full {
  min-height: calc(100vh - 155px);
}
body #app main {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
body::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.5s;
}
body.menu-open::before {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 9999 !important;
}
body a {
  color: #58A5BE;
  font-weight: 600;
}
body a:hover,
body a :hover {
  color: #92CCDE;
  text-decoration: none;
}
body a.has-underline-link {
  text-decoration: underline;
}
body a.disabled {
  pointer-events: none;
  opacity: 0.4;
}
body a.is-primary {
  color: #58A5BE;
  font-weight: 600;
  text-decoration: none;
}
body a.is-primary:hover {
  color: #92CCDE !important;
}
body .link-wrap.is-primary {
  color: #58A5BE;
  font-weight: 600;
  text-decoration: none;
}
body .link-wrap.is-primary .svg-icon-link {
  fill: #58A5BE;
}
body .link-wrap.is-primary:hover {
  color: #92CCDE;
}
body .link-wrap.is-primary:hover .svg-icon-link {
  fill: #92CCDE;
}
body .body-bg {
  background-color: #F6F6F4;
}
body .cursor-pointer {
  cursor: pointer !important;
}
body .disabled {
  pointer-events: none;
  opacity: 0.4;
}
body .text-blue {
  color: #58A5BE;
}

@media (max-width: 991px) {
  h1 {
    font-size: 0.9rem;
  }
  h3 {
    font-size: 0.9rem;
  }
}
.container-xl {
  padding: 0;
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }
}
.dropdown .dropdown-toggle::after {
  display: none;
}
.dropdown .dropdown-toggle .bi-chevron-down::before {
  transition: 0.3s;
}
.dropdown .dropdown-toggle[aria-expanded=true] .bi-chevron-down::before {
  transform: rotate(180deg);
}
.dropdown .dropdown-menu {
  border-radius: 0;
}
.dropdown .dropdown-menu .dropdown-item:hover, .dropdown .dropdown-menu .dropdown-item:focus {
  color: #242a28;
  background-color: #EBEAE5;
}
.dropdown .dropdown-menu .dropdown-item.active, .dropdown .dropdown-menu .dropdown-item:active {
  color: #242a28;
  background-color: #e3e2db;
}

.accordion-toggle .bi-chevron-right::before {
  transition: 0.3s;
}
.accordion-toggle[aria-expanded=true] .bi-chevron-right::before {
  transform: rotate(90deg);
}

.modal-content {
  border-radius: 0;
}

.modal-backdrop {
  background-color: #FFFFFF;
}

input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 4px;
  background-clip: content-box;
  border: 1px solid #c7c4b6;
  background-color: transparent;
  border-radius: 50%;
}

input[type=radio]:checked {
  background-color: #242a28;
  border-color: #242a28;
}

.bg-primary {
  background-color: #C9E6EF !important;
}

.text-success {
  color: #02FD68 !important;
}

header {
  height: 40px;
  /* Side menu navigation */
}
@media (min-width: 769px) {
  header {
    margin-bottom: 1rem;
  }
}
header nav {
  height: 100%;
}
header nav .left-container {
  height: 100%;
}
header nav .left-container .nav-btn {
  color: #FFFFFF;
  font-size: 2rem;
  cursor: pointer;
  padding: 0 0.5rem;
  height: 40px;
  display: flex;
  align-items: center;
}
header nav .logo-wrap img {
  height: 30px;
}
header nav .right-container {
  display: flex;
  align-items: center;
  height: 100%;
}
header nav .right-container .name-wrap {
  color: #FFFFFF;
}
header nav .right-container .link-wrap {
  color: #FFFFFF;
  height: 100%;
}
header nav .right-container .link-wrap .nav-btn {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
}
header nav .right-container .link-wrap .nav-btn .bi-person {
  font-size: 1.25rem;
}
header nav .right-container .nav-icon-svg-white {
  width: 20px;
  height: 20px;
  filter: invert(1) sepia(1) saturate(0) hue-rotate(0deg);
}
header .side-nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: -100%;
  height: 100vh;
  transition: 0.5s;
  z-index: 99999;
  background-color: #484D48;
}
header .side-nav .nav-link {
  margin-bottom: 0.5rem;
}
header .side-nav .nav-link, header .side-nav .bi, header .side-nav a {
  color: #FFFFFF !important;
}
header .side-nav .nav-link .nav-icon-svg, header .side-nav .bi .nav-icon-svg, header .side-nav a .nav-icon-svg {
  stroke: #FFFFFF !important;
}
header .side-nav .nav-link .nav-icon-svg.has-fill, header .side-nav .bi .nav-icon-svg.has-fill, header .side-nav a .nav-icon-svg.has-fill {
  fill: #FFFFFF !important;
}
header .side-nav .nav-link .nav-icon-svg-white, header .side-nav .bi .nav-icon-svg-white, header .side-nav a .nav-icon-svg-white {
  filter: invert(1) sepia(1) saturate(0) hue-rotate(0deg);
}
header .side-nav .nav-link :hover, header .side-nav .bi :hover, header .side-nav a :hover {
  text-decoration: none;
  color: #E3E2DB !important;
}
header .side-nav .nav-link:hover .nav-icon-svg {
  stroke: #E3E2DB !important;
}
header .side-nav .nav-link:hover .nav-icon-svg.has-fill {
  fill: #E3E2DB !important;
}
header .side-nav .nav-link:hover .nav-icon-svg-white {
  filter: invert(1) sepia(0) saturate(1) hue-rotate(52deg) opacity(0.8);
}
header .side-nav.active {
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
header .side-nav.right {
  left: auto;
  right: -100%;
}
header .side-nav.right.active {
  right: 0;
}
header .side-nav .icon-wrap {
  width: 70px;
  padding: 2rem;
  padding-bottom: 0;
  font-size: 20px;
  cursor: pointer;
}
header .side-nav .navbar-nav {
  padding: 2rem;
}
header .side-nav .navbar-nav .nav-item.main-item {
  font-weight: 600;
}
header .side-nav .navbar-nav .nav-item .nav-link {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
header .side-nav .navbar-nav .nav-item .nav-link img {
  width: 20px;
  height: 20px;
}
header .side-nav .navbar-nav .divider {
  background-color: #484D48;
}

@media (min-width: 992px) {
  header {
    height: 70px;
  }
}
@media (min-width: 768px) {
  header nav .left-container .nav-btn {
    padding: 0.5rem 1rem;
    height: 100%;
  }
  header nav .right-container .link-wrap .nav-btn {
    padding: 0.5rem 1rem;
  }
  header .side-nav {
    width: 400px;
    left: -400px;
  }
  header .side-nav::-webkit-scrollbar {
    width: 0.5rem;
  }
  header .side-nav::-webkit-scrollbar-track {
    background-color: transparent;
  }
  header .side-nav::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
.title-container {
  background: #FFFFFF;
  padding: 2rem 1rem 4rem 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.title-container.direction-row {
  flex-direction: row;
}
.title-container.direction-row .flex-columns {
  flex-direction: row;
  display: flex;
  width: 100%;
}
.title-container.direction-row strong {
  font-weight: 600;
}
.title-container.has-top-bar {
  margin-top: 0;
}
.title-container .logo-wrap {
  margin-left: auto;
}
.title-container .logo-wrap img {
  height: 60px;
  width: auto;
  margin-top: 5px;
}
.title-container .title-wrap {
  display: flex;
  align-items: center;
}
.title-container .title-wrap .title {
  font-size: 24px;
  line-height: 1.2;
}
.title-container .title-wrap .description {
  line-height: 1.2;
}
.title-container .bookmark-wrap .icon {
  background: #CAE2F0;
  padding: 0.3rem 0.7rem;
  color: #FFFFFF;
  font-size: 20px;
  margin-left: 0.5rem;
}
.title-container .left-wrap a {
  color: #99a594;
}
.title-container .btn-wrap {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}
.title-container .btn-wrap.left {
  left: 1rem;
  right: auto;
}
.title-container .btn-wrap .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.title-container.report-damage {
  background: url("../../../images/web/report-bg.png") no-repeat center;
  background-size: cover;
  height: 350px;
}
.title-container.guarantee-show .title {
  line-height: 1.125;
}

.message-container {
  background: #FFFFFF;
}
.message-container.title-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 1rem 3rem 1rem;
  position: relative;
}
.message-container.title-wrap.is-success {
  background-color: #D6FDE4;
}
.message-container.title-wrap.is-error {
  background-color: #F8C2C2;
}
.message-container.title-wrap .logo-wrap {
  position: absolute;
  top: 3rem;
  right: 5rem;
}
.message-container.title-wrap .logo-wrap img {
  width: 180px;
}
@media (max-width: 768px) {
  .message-container.title-wrap .logo-wrap {
    top: 1rem;
    right: 2rem;
  }
  .message-container.title-wrap .logo-wrap img {
    width: 80px;
  }
}
.message-container.desc-wrap {
  min-height: 200px;
  padding: 3rem 1rem;
}
.message-container.desc-wrap .btn-wrap {
  margin: 3rem 0 4rem;
  text-align: center;
}

.heading-container {
  background: #FFFFFF;
  padding: 1rem 5rem;
}
.heading-container .title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}
.heading-container .notify {
  display: flex;
  cursor: pointer;
}
.heading-container .notify .icon-wrap {
  position: relative;
}
.heading-container .notify .icon-wrap .icon {
  width: 20px;
}
.heading-container .notify .counter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #02FD68;
  min-width: 15px;
  height: 15px;
  font-size: 10px;
  border-radius: 20px;
}

.overview-container {
  background: #FFFFFF;
}
.overview-container.is-success {
  background: #E5FFD5;
}
.overview-container.is-locked {
  background: #FCE4BE;
}
.overview-container.is-canceled {
  background: #E0E0E0;
}
.overview-container.is-failed {
  background: #ff4b67;
}

.content-container {
  padding: 2rem 1rem;
  background: #FFFFFF;
}
.content-container .title-wrap .title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}
.content-container .logo-wrap {
  margin-left: auto;
}
.content-container .logo-wrap img {
  height: 60px;
  width: auto;
  margin-top: 5px;
}
.content-container .bookmark-wrap {
  margin-left: auto;
  text-align: right;
  color: #58A5BE;
  font-weight: 600;
  text-decoration: none;
}
.content-container .bookmark-wrap:hover {
  color: #92CCDE;
}
.content-container .bookmark-wrap .icon {
  display: flex;
  align-items: center;
}
.content-container .bookmark-wrap .icon i {
  margin-right: 0.25rem;
  margin-bottom: -2px;
}
.content-container .guarantee-total {
  padding: 2rem 1rem;
  background: #d8e3d2;
}
.content-container.product-catalog-container {
  padding: 1rem 0;
}
.content-container .edit-icon-wrap {
  position: absolute;
  right: 5rem;
  top: 3rem;
}
.content-container .component-summary {
  position: relative;
}
.content-container .component-summary .edit-icon-wrap {
  right: 0;
  top: 0;
}

.search-container {
  padding: 2rem 5rem;
}
.search-container.is-primary {
  background: #C9E6EF;
}
.search-container .input-group {
  width: 100%;
  height: 40px;
}
.search-container .input-group .form-control {
  border-radius: 0;
  border-color: #E4E2DC;
  height: 100%;
}
.search-container .input-group .form-control:focus {
  border-color: #858585;
  box-shadow: none;
}
.search-container .input-group .btn {
  width: 40px;
  border-radius: 0;
  background: #F4F3F2;
  color: #242a28;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  border: 1px solid #E4E2DC;
  border-left: 0;
  cursor: pointer;
  padding-top: 2px;
  padding-left: 2px;
  padding-right: 0;
  padding-bottom: 0;
}
.search-container .input-group .btn:focus {
  box-shadow: none;
}
.search-container .input-group .btn:hover {
  background-color: #dbdad9;
}
.search-container ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-style: normal;
  line-height: 1rem;
}
.search-container :-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-style: italic;
  line-height: 1rem;
}
.search-container ::-ms-input-placeholder { /* Microsoft Edge */
  font-style: italic;
  line-height: 1rem;
}

.filter-container {
  padding: 2rem 5rem;
}
.filter-container.is-primary {
  background: #C9E6EF;
}
.filter-container .filter-select-hsw {
  position: relative;
  display: block;
  width: 100%;
  height: 40px;
  margin: 0 auto;
  background-color: #FFFFFF;
  z-index: 0;
}
.filter-container .filter-select-hsw:not(.form-date):after { /* triangle: width 13px, heigh 9px */
  content: "";
  position: absolute;
  right: 13.5px;
  top: 15.5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 6.5px 0 6.5px;
  border-color: #484D48 transparent transparent transparent;
  z-index: -1;
}
.filter-container .filter-select-hsw .form-control {
  border: 1px solid #E4E2DC;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  margin: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 55px 0 15px;
  color: #484D48;
}
.filter-container .filter-select-hsw .form-control:focus {
  border-color: #858585;
  box-shadow: none;
}
.filter-container .filter-select-hsw:not(.form-date) .form-control {
  cursor: pointer;
}
.filter-container .filter-select-hsw.form-date .form-control {
  padding: 0 10px 0 15px;
}

.btn-container {
  background: #484D48;
  height: 40px;
  padding: 0 1rem;
}
.btn-container.top {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
}
.btn-container .page-back {
  color: #FFFFFF;
}
.btn-container.bottom {
  height: auto;
  position: sticky;
  bottom: 60px;
  z-index: 1;
  background: #FFFFFF;
  padding: 1rem;
  box-shadow: 0 0 10px -5px #242a28;
  clip-path: inset(-100% 0 0 0);
}
.btn-container.bottom.scroll {
  box-shadow: none;
}

.tab-container .tabs {
  display: flex;
}
.tab-container .tabs .tab {
  padding: 1.25rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #C9E6EF;
  color: #242a28;
  line-height: 1;
  transition: background-color 0.2s ease-in-out;
}
.tab-container .tabs .tab.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.tab-container .tabs .tab:not(:last-child) {
  border-right: 0.25rem solid #F6F6F4;
}
.tab-container .tabs .tab .number {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0.25rem;
  background-color: #484D48;
  color: #FFFFFF;
}
.tab-container .tabs .tab:hover, .tab-container .tabs .tab.active {
  background: #FFFFFF;
  color: #242a28;
  text-decoration: none;
}
.tab-container .tabs .tab:hover:hover .number, .tab-container .tabs .tab.active:hover .number {
  border-color: #242a28;
}

.tool-container {
  background: #FFFFFF;
  padding: 1rem;
  position: relative;
}
.tool-container.is-primary {
  background: #C9E6EF;
}
.tool-container .title-wrap {
  margin-bottom: 2rem;
}
.tool-container .title-wrap .title, .tool-container .title-wrap h1 {
  font-size: 24px;
  line-height: 1.2;
}
.tool-container .title-wrap .collapse-btn {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  color: #242a28;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tool-container .title-wrap .collapse-btn::after {
  font-family: "bootstrap-icons";
  content: "\f64d";
  position: absolute;
  font-size: 1.5rem;
  transition: transform 0.3s ease-in-out;
  -ms-transition: -ms-transform 0.3s ease-in-out;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
}
.tool-container .title-wrap .collapse-btn[aria-expanded=true]::after {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.tool-container .logo-wrap {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
}
.tool-container .logo-wrap img {
  height: 60px;
  width: auto;
  margin-top: 5px;
}
.tool-container .accordion .accordion-item {
  background-color: #F5F5F5;
}
.tool-container .accordion .accordion-item:not(:last-child) {
  margin-bottom: 1rem;
}
.tool-container .accordion .accordion-item .header {
  display: flex;
}
.tool-container .accordion .accordion-item .header .collapse-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  color: #242a28;
  margin-left: auto;
}
.tool-container .accordion .accordion-item .header .collapse-btn::after {
  font-family: "bootstrap-icons";
  content: "\f64d";
  position: absolute;
  font-size: 1.5rem;
  transition: transform 0.3s ease-in-out;
  -ms-transition: -ms-transform 0.3s ease-in-out;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
}
.tool-container .accordion .accordion-item .header .collapse-btn[aria-expanded=true]::after {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.alert-container {
  padding: 0 3rem;
  background-color: #fdf8e4;
  font-weight: 600;
  margin-top: 0.5rem;
}
.alert-container .title {
  margin-bottom: 2rem;
}
.alert-container .description {
  font-size: 16px;
  width: 75%;
  margin-bottom: 3rem;
}
.alert-container .btn-wrap {
  text-align: center;
}
.alert-container .btn-wrap .icon {
  width: 18px;
}

.customer-alert-container {
  display: flex;
  justify-content: center;
  padding: 3rem 4rem;
  background-color: #FFFFFF;
  font-weight: 600;
  font-size: 20px;
}

.info-container {
  padding: 3rem 4rem;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin: 0.5rem 0 1rem;
}
.info-container.is-primary {
  background-color: #C9E6EF;
}
.info-container.is-waring {
  background-color: #FCE4BE;
}
.info-container.is-danger {
  background-color: #F8C2C2;
}
.info-container.text-normal-weight {
  font-weight: normal;
}
.info-container.text-normal-weight .w-100 {
  padding-left: 25px;
  margin-bottom: 0;
}

.actions-wrapper {
  display: flex;
  align-items: center;
}
.actions-wrapper .btn {
  margin: 32px auto 0;
  display: flex;
  align-items: center;
}
.actions-wrapper .btn img {
  margin-right: 4px;
}
.actions-wrapper.success {
  flex-direction: row;
  align-items: center;
}
.actions-wrapper.success .btn {
  margin: 32px auto;
  max-width: 33%;
}
.actions-wrapper.success .btn:first-child {
  margin-right: 15px;
}
.actions-wrapper.success .btn:last-child {
  margin-left: 15px;
}

.hero-container {
  background: #FFFFFF;
  padding: 3rem 5rem;
  position: relative;
  background: #C9E6EF;
}
.hero-container .title-wrap {
  margin-bottom: 2rem;
}
.hero-container .logo-wrap {
  text-align: center;
}
.hero-container .logo-wrap .logo {
  width: auto;
  height: 60px;
}
.hero-container .btn-wrap {
  text-align: center;
}
.hero-container .btn-wrap .btn {
  padding-right: 4rem;
  padding-left: 4rem;
}

.hero-btn-container {
  margin: 0 0 0.5rem 0;
}
.hero-btn-container .btn-wrap {
  font-size: 20px;
  font-weight: 600;
  color: #FFFFFF;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #252928;
  cursor: pointer;
}
.hero-btn-container .btn-wrap i {
  --icon-size: 35px;
  background-color: #FFFFFF;
}
.hero-btn-container .btn-wrap:hover {
  background-color: #2BFD68;
  color: #252928;
}
.hero-btn-container .btn-wrap:hover i {
  background-color: #252928;
}
.hero-btn-container .btn-wrap:not(:last-child) {
  border-right: 1px solid #F5F5F5;
}
.hero-btn-container .btn-wrap .icon-wrap {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.hero-btn-container .btn-wrap.disabled {
  pointer-events: none;
  opacity: 1;
  color: #5c5c5c;
}
.hero-btn-container .btn-wrap.disabled i {
  background-color: #5c5c5c;
}

.tool-tip-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.tool-tip-container .show-tool-tip {
  cursor: pointer;
  margin-right: 0.5rem !important;
}
.tool-tip-container .tool-tip-info-wrap {
  background: #ffffff;
  width: 250px;
  padding: 1rem;
  z-index: 999;
  top: 1.25rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137);
  position: absolute;
  font-size: 14px;
}
.tool-tip-container .tool-tip-info-wrap .close-tool-tip {
  cursor: pointer;
}

@media (min-width: 768px) {
  .title-container {
    padding: 1rem 5rem;
  }
  .title-container .left-wrap {
    margin-top: 0;
    margin-left: auto;
  }
  .title-container .left-wrap a {
    color: #242a28;
  }
  .title-container .btn-wrap.left {
    left: 2rem;
    right: auto;
  }
  .title-container.guarantee-show {
    padding: 38px;
  }
  .title-container.guarantee-show .title {
    line-height: 1.25;
  }
  .search-container {
    padding: 3rem 5rem;
  }
  .filter-container {
    padding: 3rem 5rem;
  }
  .content-container {
    padding: 3rem 5rem;
  }
  .container-nest {
    padding: 0 4rem;
  }
  .btn-container {
    background: #FFFFFF;
    height: auto;
    padding: 2rem 10rem 0 2rem;
  }
  .btn-container.top {
    position: relative;
  }
  .btn-container .page-back {
    color: #242a28;
  }
  .btn-container .tool .tool-item {
    width: auto;
    border-left: 0;
    color: #242a28;
    padding-left: 1rem;
  }
  .btn-container.bottom {
    bottom: 0;
  }
  .tool-container {
    padding: 3rem 5rem;
  }
  .tool-container .btn-wrap {
    position: absolute;
    top: 0.75rem;
    right: 1.5rem;
  }
  .tool-container .btn-wrap.left {
    left: 1rem;
    right: auto;
  }
}
@media (max-width: 1199.98px) {
  .tool-list {
    padding: 2rem 2rem !important;
  }
}
.legal-container .title-wrap {
  background-color: #E3E2DB;
  padding: 2rem 3rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.legal-container .content-wrap {
  padding: 2rem 3rem;
  background-color: #FFFFFF;
  min-height: 300px;
}

.template-header-container {
  background-color: white;
  padding: 3rem 4rem;
  position: relative;
  border-bottom: 2px solid #F4F3F2;
}
@media (max-width: 768px) {
  .template-header-container {
    padding: 25px;
  }
}
.template-header-container .search-wrap {
  display: flex;
  justify-content: center;
}

.plr-4r {
  padding-left: 4rem;
  padding-right: 4rem;
}

.template-header-container.yellow {
  background: #FFFED9;
}
.template-header-container.yellow .title-wrap {
  color: #252928;
  display: flex;
  align-items: center;
}
.template-header-container.yellow .title-wrap h1 {
  font-size: 16px;
  margin-bottom: 0;
}
.template-header-container.yellow .title-wrap img {
  margin-right: 5px;
}
.template-header-container.yellow .sub-title-wrap {
  padding-left: 25px;
  padding-top: 15px;
}
.template-header-container.yellow .sub-title-wrap p {
  margin-bottom: 0;
}
.template-header-container.yellow .sub-title-wrap .btn {
  font-weight: normal;
  margin: 0 !important;
  height: auto;
  padding: 0;
  font-size: 16px;
}

form .form-group label {
  font-size: 13px;
}
form .is-primary .svg-icon-link {
  margin-left: 5px;
}
form .is-primary path {
  fill: #58A5BE;
}
form .is-primary:hover path {
  fill: #92CCDE;
}

.ml0 {
  margin-left: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.invoice-name {
  display: flex;
  align-items: center;
}
.invoice-name img {
  margin-right: 8px;
}

.cc-link.with-svg {
  display: flex;
  align-items: center;
}
.cc-link.with-svg img {
  margin-right: 4px;
}
.cc-link.with-svg:hover img {
  opacity: 0.5;
}

.media-row:nth-child(2n+1) {
  background: #F6F6F4;
  padding: 3px 0 !important;
  margin-bottom: 3px;
}

.content-container.yellow {
  background: #FFFED9;
}

.info-wrap {
  display: flex;
  align-items: center;
}
.info-wrap p {
  margin: 0 0 0 5px;
}

.summary-row {
  display: flex;
}

.yellow {
  background: #FFFED9;
  justify-content: left;
}

.show-card .blade-show {
  margin-bottom: 0;
  align-items: center;
}
.show-card .blade-show .logo-wrap {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
}
.show-card .blade-show .logo-wrap img {
  height: 60px !important;
  width: auto;
  margin-top: 5px;
}
.show-card .card-head {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0.25rem;
}
.show-card .preis-columns {
  padding-left: 5rem;
}
.show-card .btn-link.btn-edit {
  font-weight: 600;
  color: #58a5be;
  cursor: pointer;
  text-decoration: none;
}
.show-card .btn-link.btn-edit img {
  filter: invert(0.6) sepia(1) saturate(100) hue-rotate(190deg) opacity(0.6);
}
.show-card .btn-link.btn-edit:hover {
  color: #92ccde;
}
.show-card .btn-link.btn-edit:hover img {
  filter: invert(0.6) sepia(1) saturate(100) hue-rotate(190deg) opacity(0.4);
}
.show-card .btn {
  border: none;
}
.show-card .btn.btn-download img {
  filter: invert(1) brightness(0) opacity(0.65);
}
.show-card .btn:hover {
  border: none;
}

.btn-link img {
  filter: invert(65%) sepia(10%) saturate(1997%) hue-rotate(148deg) brightness(91%) contrast(82%);
}

.btn-link:hover img,
.btn-link img:hover {
  filter: invert(85%) sepia(17%) saturate(872%) hue-rotate(161deg) brightness(92%) contrast(88%);
}

.no-border {
  border: none !important;
}
.no-border:hover {
  border: none !important;
}

.fs-16 {
  font-size: 16px;
}

#app {
  overflow-x: hidden;
}

footer {
  color: #FFFFFF;
  padding: 15px 25px;
  font-size: 16px;
  display: flex;
  align-items: center;
}
footer a {
  color: #FFFFFF;
}
footer a:hover {
  color: #FFFFFF;
}
footer .footer-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.auth-body {
  background: url("../../images/auth/auth-bg.png") no-repeat center fixed;
  background-size: cover;
}
.auth-body .auth-header {
  background-color: #343a40;
}
.auth-body .auth-main {
  height: calc(100vh - 40px);
}
.auth-body .auth-main .card-title-wrap {
  position: relative;
  height: 4rem;
  margin-bottom: 1rem;
  text-align: center;
}
.auth-body .auth-main .card-title-wrap .label {
  position: absolute;
  right: 0;
  top: 0;
  width: 4rem;
  height: 4rem;
}
.auth-body .login-registerplaceholder {
  display: flex;
  align-items: center;
}
.auth-body .login-registerplaceholder .line {
  height: 1px;
  border-top: 2px solid #000000;
  flex-grow: 1;
}
.auth-body .login-registerplaceholder .text {
  flex-grow: 0;
}
.auth-body .hsw-login {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid #D5D5D5;
  background-color: #F6F6F4;
  color: #484d48;
  font-size: 14px;
}
.auth-body .hsw-login:hover,
.auth-body .hsw-login :hover {
  color: #484d48;
  background: #e9edef;
}
.auth-body .hsw-login img {
  vertical-align: middle;
  max-height: 100%;
}

@media (min-width: 768px) {
  .auth-body .auth-main {
    height: calc(100vh - 80px);
  }
}
body {
  height: 100vh;
}

.bg-gradient-primary {
  background: #99a594;
}

.btn-outline-grey {
  padding-top: 7px;
  padding-bottom: 7px;
}

.btn-hsw {
  background-color: #2f4c35;
  border-color: #2f4c35;
  color: #ffffff;
}
.btn-hsw:hover, .btn-hsw:focus {
  color: #ffffff;
  background-color: rgba(47, 76, 53, 0.6470588235);
  border-color: rgba(47, 76, 53, 0.6470588235);
}

.select-hsw {
  height: 50px;
  border-radius: 0;
}

.hsw-bg {
  background: #E4E1DB;
}

.link-back {
  color: #000000 !important;
  font-weight: 600;
  text-decoration: none !important;
}
.link-back:hover {
  color: #2F4C35 !important;
}