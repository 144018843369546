.alert-hsw {
    background: $light-green;
    color: $white;
    border-radius: 0;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-top: 1rem solid $light-green;
        right: 1.5rem;
        bottom: -1rem;
    }
}
