$brand-color: #C9E6EF;
$brand-light-color: #CAE2F0;
$brand-dark-color: #172a36;
$bg-color: #F5F5F5;
$white: #FFFFFF;
$black: #242a28;
$grey: #E0E0E0;
$dark-grey: #8e8e8e;
$pale-green: #eef4ec;
$primary: #C9E6EF;
$success: #02FD68;
$warning-light: #fdf8e4;
$hsw-bg-color: #E4E1DB;
$dark: #484D48;

$brand-accent-color: #ff4b67; //Accent color : pink

$green: #2f4c35;
$light-green: #99a594;
$beige: #c7c4b6;
$light-beige: #e3e2db;
$white-beige: #f6f6f4;

$body-bg: #F6F6F4;
$body-text-color: #484d48;

$btn-light-bg: #FFFFFF;
$btn-light-hover-bg: #E9EDEF;

$btn-dark-bg: #484d48;
$btn-dark-hover-bg: #252928;

$btn-primary-bg: #C8E5EE;
$btn-primary-hover-bg: #ADD9E6;

$btn-success-bg: #02FD68;
$btn-success-hover-bg: #26E86C;

$btn-text-color-dark: #484D48;
$btn-text-color-light: #FFFFFF;

$global-link-color: #58A5BE;
$global-link-hover-color: #92CCDE;

$alert-bg: $warning-light;

$h1-font-size: 28px;
$h3-font-size: 20px;

$tool-box-bg-success: #E5FFD5;
$tool-box-bg-locked: #FCE4BE;
$tool-box-bg-canceled: #E0E0E0;
$tool-box-bg-failed: #ff4b67;

$info-box-primary-bg: #C9E6EF;
$info-box-waring-bg: #FFB44D;

$message-success-bg: #D6FDE4;
$message-error-bg: #F8C2C2;

$web-title-bg: #E3E2DB;

$container-padding: 3rem 4rem;

$font-weight-medium: 600;

$nav-bg: #484D48;
$nav-link-color: #FFFFFF;
$nav-link-hover-color: #E3E2DB;

$primary-link-color: #58A5BE;
$primary-link-hover-color: #92CCDE;

$hsw-link-color: #000000;
$hsw-link-hover-color: #26e86c;

$cc-link-color: #58a5be;
$cc-link-hover-color: #92ccde;

$well-done-color: #D6FDE4;
$attention-color: #FFFED9;
$warning-color: #FCE4BE;
$danger-color: #F8C2C2;

$hsw-button-primary: #2BFD68;
$hsw-button-primary-border: #2BFD68;
$hsw-button-primary-hover: #26E86C;
$hsw-button-primary-hover-border: #25E46A;

$hsw-button-secondary: #EBEBEB;
$hsw-button-secondary-border: #252928;
$hsw-button-secondary-hover: #FFFFFF;
$hsw-button-secondary-hover-border: #252928;

$cc-button-primary: #2BFD68;
$cc-button-primary-border: #2BFD68;
$cc-button-primary-hover: #26E86C;
$cc-button-primary-hover-border: #25E46A;

$cc-button-secondary: #C9E6EF;
$cc-button-secondary-border: #C9E6EF;
$cc-button-secondary-hover: #92CCDE;
$cc-button-secondary-hover-border: #F7F7F7;
$cc-button-on-secondary-color: #252928;

$cc-button-tabs-background: #252928;
$cc-button-tabs-color: #FFFFFF;
$cc-button-tabs-color-disabled: #5c5c5c;
$cc-button-tabs-hover: #2BFD68;
$cc-button-tabs-hover-color: #252928;

$cc-form-check-primary: #2BFD68;


$container-padding-x: 5rem;

$h3-font-size: $font-size-base * 1.125;
$h1-font-size: $font-size-base * 1.5;
