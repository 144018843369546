.container-xl {
    padding: 0;
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .w-sm-auto {
        width: auto !important;
    }
}


.dropdown {
    .dropdown-toggle {
        &::after {
            display: none;
        }

        .bi-chevron-down::before {
            transition: 0.3s;
        }

        &[aria-expanded="true"] {
            .bi-chevron-down::before {
                transform: rotate(180deg);
            }
        }
    }

    .dropdown-menu {
        border-radius: 0;

        .dropdown-item:hover, .dropdown-item:focus {
            color: $black;
            background-color: #EBEAE5;
        }

        .dropdown-item.active, .dropdown-item:active {
            color: $black;
            background-color: $light-beige;
        }
    }
}

.accordion-toggle {
    .bi-chevron-right::before {
        transition: 0.3s;
    }

    &[aria-expanded="true"] {
        .bi-chevron-right::before {
            transform: rotate(90deg);
        }
    }
}


// Modal
.modal-content {
    border-radius: 0;
}

.modal-backdrop {
    background-color: $white;
}

// Radio button
input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 4px;
    background-clip: content-box;
    border: 1px solid $beige;
    background-color: transparent;
    border-radius: 50%;
}

input[type="radio"]:checked {
    background-color: $black;
    border-color: $black;
}

.bg-primary {
    background-color: $primary !important;
}

.text-success {
    color: $success !important;
}
